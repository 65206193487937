const words = [{
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'rebeccapurple' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const rebeccapurple =  {
    number: `#${count.toLocaleString()} | ${percent}%`,
    words
}