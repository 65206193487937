import { getChapter, getRead } from '../verse'

const verses = {
    '110:1': {
        number: '١',
        words: [{
            transliteration: 'idhā',
            arabic: 'إِذَا',
            translations: [ 'When' ],
            color: 'darkorange',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'jāa',
            arabic: 'جَآءَ',
            translations: [ 'comes' ],
            color: 'darkgrey',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'naṣru',
            arabic: 'نَصْرُ',
            translations: [ '(the) Help' ],
            color: 'deepskyblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'l-lahi',
            arabic: 'ٱللَّهِ',
            translations: [ '(of) Allah' ],
            color: 'royalblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'wal-fatḥu',
            arabic: 'وَٱلْفَتْحُ',
            translations: [ 'and the Victory' ],
            color: 'pink',
            punctuations: [ '', '' ],
            count: '?'
        }]
    },
    '110:2': {
        number: '٢',
        words: [{
            transliteration: 'wara-ayta',
            arabic: 'وَرَأَيْتَ',
            translations: [ 'And you see' ],
            color: 'white',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'l-nāsa',
            arabic: 'ٱلنَّاسَ',
            translations: [ 'the people' ],
            color: 'chartreuse',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'yadkhulūna',
            arabic: 'يَدْخُلُونَ',
            translations: [ 'entering' ],
            color: 'cyan',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'fī',
            arabic: 'فِى',
            translations: [ 'into' ],
            color: 'gold',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'dīni',
            arabic: 'دِينِ',
            translations: [ '(the) religion' ],
            color: 'deepskyblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'l-lahi',
            arabic: 'ٱللَّهِ',
            translations: [ '(of) Allah' ],
            color: 'royalblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'afwājan',
            arabic: 'أَفْوَاجًا',
            translations: [ '(in) multitudes' ],
            color: 'cyan',
            punctuations: [ '', '' ],
            count: '?'
        }]
    },
    '110:3': {
        number: '٣',
        words: [{
            transliteration: 'fasabbiḥ',
            arabic: 'فَسَبِّحْ',
            translations: [ 'Then glorify' ],
            color: 'pink',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'biḥamdi',
            arabic: 'بِحَمْدِ',
            translations: [ 'with (the) praises' ],
            color: 'skyblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'rabbika',
            arabic: 'رَبِّكَ',
            translations: [ '(of) your Lord' ],
            color: 'royalblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'wa-is\'taghfir\'hu',
            arabic: 'وَٱسْتَغْفِرْهُ',
            translations: [ 'and ask His forgiveness' ],
            color: 'skyblue',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'innahu',
            arabic: 'إِنَّهُۥ',
            translations: [ 'Indeed, He' ],
            color: 'gold',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'kāna',
            arabic: 'كَانَ',
            translations: [ 'is' ],
            color: 'white',
            punctuations: [ '', '' ],
            count: '?'
        }, {
            transliteration: 'tawwāban',
            arabic: 'تَوَّابًۢا',
            translations: [ 'Oft-Returning' ],
            color: 'skyblue',
            punctuations: [ '', '' ],
            count: '?'
        }]
    }
}

export const chapter110 = () => getChapter(verses)

export const chapter110Read = () => getRead(verses)