import { chapter1 } from './1'
import { chapter103 } from './103'
import { chapter108 } from './108'
import { chapter112 } from './112'
import { chapter113 } from './113'
import { chapter114 } from './114'


const title = (chapterTitle) => (<div>
  <h2>{chapterTitle}</h2>
  <br></br>
</div>)

function Study() {
  return (
    <div className="App ">
      <header className="App-header">
        <p>Under Construction!!!!</p>
        { title('1 - The Opening') }
        { chapter1() }
        <br></br>
        { title('103 - The Declining Day') }
        { chapter103() }
        <br></br>
        { title('108 - The Abundance') }
        { chapter108() }
        <br></br>
        { title('112 - Sincerity') }
        { chapter112() }
        <br></br>
        { title('113 - Day Break') }
        { chapter113() }
        <br></br>
        { title('114 - Mankind') }
        { chapter114() }
      </header>
    </div>
  );
}

export default Study;
