import { PieChart } from 'react-minimal-pie-chart';

export const pie = () => (<PieChart
    data={[
      { title: 'white', value: 5, color: 'white', name: 'Distinct' },
      { title: 'darkgrey', value: 5, color: 'darkgrey', name: 'Nouns' },
      { title: 'slategray', value: 5, color: 'slategray', name: '?' },
      { title: 'darkslategrey', value: 5, color: 'darkslategrey', name: '?' },
      { title: 'cadetblue', value: 5, color: 'cadetblue', name: 'Last Day' },
      { title: 'skyblue', value: 5, color: 'skyblue', name: 'Attributes of God' },
      { title: 'royalblue', value: 5, color: 'royalblue', name: 'Prophets' },
      { title: 'deepskyblue', value: 5, color: 'deepskyblue', name: 'Religion' },
      { title: 'cyan', value: 5, color: 'cyan', name: 'Identification' },
      { title: 'mediumspringgreen', value: 5, color: 'mediumspringgreen', name: 'Questions' },
      { title: 'chartreuse', value: 5, color: 'chartreuse', name: 'People' },
      { title: 'yellowgreen', value: 5, color: 'yellowgreen', name: 'Life' },
      { title: 'gold', value: 5, color: 'gold', name: 'Particles' },
      { title: 'darkorange', value: 5, color: 'darkorange', name: '?' },
      { title: 'sienna', value: 5, color: 'sienna', name: '?' },
      { title: 'brown', value: 5, color: 'brown', name: '?' },
      { title: 'red', value: 5, color: 'red', name: 'Misguided' },
      { title: 'hotpink', value: 5, color: 'hotpink', name: '?' },
      { title: 'fuchsia', value: 5, color: 'fuchsia', name: 'Verbs' },
      { title: 'rebeccapurple', value: 5, color: 'rebeccapurple', name: '?' },
      // { title: 'black', value: 50, color: 'seashell', name: '?' },
    ]}
    style={{ height: '250px' }} 
  />)