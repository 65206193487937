import Row from 'react-bootstrap/Row'

import { zero } from './azaan'

const title = (chapterTitle) => (<div>
  <h2>{chapterTitle}</h2>
  <br></br>
</div>)

function Prayer() {
  return (
    <div className="App ">
      <header className="App-header">
        <Row>
          { title('Call to Prayer') }
          { zero() }
        </Row>
      </header>
    </div>
  );
}

export default Prayer;
