export const food = {
    'f1': {
        number: 'Before Eating',
        words: [{
            count: '?',
            arabic: 'بِسْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'ّIn the name of' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَعَلٰى',
            transliteration: 'waeala',
            color: 'white',
            translations: [ 'and on' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بَرَ كَتِ',
            transliteration: 'bara-kate',
            color: 'white',
            translations: [ 'the blessings' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }]
    },
    'f2': {
        number: 'Middle of Eating',
        words: [{
            count: '?',
            arabic: 'بِسْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'ّIn the name of' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَوَّلُهُ',
            transliteration: 'aawaluh',
            color: 'white',
            translations: [ 'the first' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاٰخِرُهُ',
            transliteration: 'wakhiruh',
            color: 'white',
            translations: [ 'and the last' ],
            punctuations: [ '', '' ]
        }]
    },
    'f3': {
        number: 'After Eating',
        words: [{
            count: '?',
            arabic: 'اَلْحَمْدُ',
            transliteration: 'aalhamdu',
            color: 'white',
            translations: [ 'All praise belongs to' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِلّٰهِ ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْ',
            transliteration: 'aladhia',
            color: 'white',
            translations: [ 'who' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَطْعَمَنَا ',
            transliteration: 'aateamana',
            color: 'white',
            translations: [ 'fed us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَسَقَانَا',
            transliteration: 'wasaqana',
            color: 'white',
            translations: [ 'and quenched our thirst' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَجَعَلَنَا',
            transliteration: 'wajaealana',
            color: 'white',
            translations: [ 'and made us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَ',
            transliteration: 'mina',
            color: 'white',
            translations: [ 'from' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْمُسْلِمِيْنَ',
            transliteration: 'almuslimin',
            color: 'white',
            translations: [ 'muslims' ],
            punctuations: [ '', '' ]
        }]
    },
    'f4': {
        number: 'Drink Milk',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بَارِكْى',
            transliteration: 'barika',
            color: 'white',
            translations: [ 'bless' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَنَا',
            transliteration: 'lana',
            color: 'white',
            translations: [ 'for us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فِيْهٖ',
            transliteration: 'fihٖe',
            color: 'white',
            translations: [ 'in it' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَزِدْنَا',
            transliteration: 'wazidna',
            color: 'white',
            translations: [ 'we increased' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْهُ',
            transliteration: 'minhu',
            color: 'white',
            translations: [ 'from him' ],
            punctuations: [ '', '' ]
        }]
    },
}