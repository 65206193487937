

export const chapter54 = {
    '0:0': {
        number: '٥٤:١٧',
        words: [{
            count: '<span style="color: yellow">406</span>',
            arabic: 'وَ&#x200d;<span style="color: yellow">&#x200d;لَقَدْ</span>',
            transliteration: 'wa<span style="color: yellow">laqad</span>',
            color: 'gold',
            translations: [ 'And <span style="color: yellow">certainly</span>' ],
            punctuations: [ '', ',' ]
        }, {
            count: '6',
            arabic: 'يَسَّرْ&#x200d;<span style="color: cyan">&#x200d;نَا</span>',
            transliteration: 'yassar<span style="color: cyan">nā</span>',
            color: 'fuchsia',
            translations: [ '<span style="color: cyan">we</span> [made] easy' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: skyblue">70</span>',
            arabic: 'ٱلْ&#x200d;<span style="color: skyblue">&#x200d;قُرْءَانَ</span>',
            transliteration: 'l-<span style="color: skyblue">qur\'āna</span>',
            color: 'white',
            translations: [ 'the <span style="color: skyblue">Quran</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: darkgrey">151?</span>',
            arabic: 'لِ&#x200d;<span style="color: darkgrey">&#x200d;لذِّكْرِ</span>',
            transliteration: 'li<span style="color: darkgrey">ldhik\'ri</span>',
            color: 'gold',
            translations: [ 'for <span style="color: darkgrey">remembrance</span>' ],
            punctuations: [ '', '.' ]
        }]
    },
  }