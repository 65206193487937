const words = [{
    count: '562',
    arabic: 'عَلِمَ',
    transliteration: '\'alima',
    translations: [ 'he knew' ]
}, {
    count: '344',
    arabic: 'جَعَلَ',
    transliteration: 'ja\'ala',
    translations: [ 'he made' ]
}, {
    count: '332',
    arabic: 'قُلْ',
    transliteration: 'qul',
    translations: [ 'say' ]
}, {
    count: '318',
    arabic: 'عَمِلَ',
    transliteration: '\'amila',
    translations: [ 'he did' ]
}, {
    count: '258',
    arabic: 'اَمَنُوْا',
    transliteration: '\'aamanoo',
    translations: [ 'they believed' ]
}, {
    count: '237',
    arabic: 'خَلَقَ',
    transliteration: 'khalaqa',
    translations: [ 'he created' ]
}, {
    count: '171',
    arabic: 'خَآءَ',
    transliteration: 'jaa\'a',
    translations: [ 'he came' ]
}, {
    count: '151',
    arabic: 'زَكَرَ',
    transliteration: 'zakara',
    translations: [ 'he remembered' ]
}, {
    count: '142',
    arabic: 'عَبَدَ',
    transliteration: '\'abada',
    translations: [ 'he worshipped' ]
}, {
    count: '100',
    arabic: 'فَعَلَ',
    transliteration: 'fa\'ala',
    translations: [ 'he did' ]
}, {
    count: '98',
    arabic: 'سَمِعَ',
    transliteration: 'sami\'a',
    translations: [ 'he listened' ]
}, {
    count: '56',
    arabic: 'شَاءَ',
    transliteration: 'shaa\'a',
    translations: [ 'wills' ]
}, {
    count: '55',
    arabic: 'اِنْزَلْنَا',
    transliteration: '\'anzalna',
    translations: [ 'We revealed' ]
}, {
    count: '50',
    arabic: 'ضَرَبَ',
    transliteration: 'daraba',
    translations: [ 'he hit', 'he stroke' ]
}, {
    count: '25',
    arabic: 'فَتَحَ',
    transliteration: 'fataha',
    translations: [ 'he opened' ]
}, {
    count: '7',
    arabic: 'أَعُوذُ',
    transliteration: 'aʿūdhu',
    translations: [ 'I seek refuge'],
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'fuchsia' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const fuchsia =  {
    number: `#${count.toLocaleString()} Verbs ${percent}%`,
    words
}