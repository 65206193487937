const words = [{
    count: '382',
    arabic: 'ءَايَة',
    transliteration: 'aayah',
    translations: [ 'sign' ]
}, {
    count: '261',
    arabic: 'كِتَاب',
    transliteration: 'kitaab',
    translations: [ 'a book' ]
}, {
    count: '247',
    arabic: 'اَلْحَقِّ',
    transliteration: 'al-haqq',
    translations: [ 'the truth' ]
}, {
    count: '230',
    arabic: 'مُؤْمِن',
    transliteration: 'mu\'min',
    translations: [ 'believer' ]
}, {
    count: '176',
    arabic: 'اَلسَّبِيْل',
    transliteration: 'As-sabeel',
    translations: [ 'the way' ]
}, {
    count: '149',
    arabic: 'اَلْحَمْد',
    transliteration: '\'al-hamd',
    translations: [ 'All praises and thanks' ]
}, {
    count: '136',
    arabic: 'صَالِح',
    transliteration: 'saalih',
    translations: [ 'a righteous person' ]
}, {
    count: '92',
    arabic: '?',
    transliteration: '?',
    translations: [ 'religion', 'law', 'judgment' ]
}, {
    count: '88',
    arabic: 'مَلَك',
    transliteration: 'malak',
    translations: [ 'angel']
}, {
    count: '83',
    arabic: 'اَلصَّلَوة',
    transliteration: 'as-salat',
    translations: [ 'the prayer']
}, {
    count: '80',
    arabic: 'نَصْر',
    transliteration: 'nasr',
    translations: [ 'help']
}, {
    count: '70',
    arabic: 'اَلْقُرْاَن',
    transliteration: 'Al-quraan',
    translations: [ 'the Quran' ]
}, {
    count: '46',
    arabic: 'طَيِّبَات',
    transliteration: 'tayyibaat',
    translations: [ 'good things' ]
}, {
    count: '45',
    arabic: 'اَلصِّرَاط',
    transliteration: 'As-siraat',
    translations: [ 'the path' ]
}, {
    count: '42',
    arabic: 'سَلَم',
    transliteration: 'salaam',
    translations: [ 'peace' ]
}, {
    count: '42',
    arabic: 'مُسْلِم',
    transliteration: 'muslim',
    translations: [ 'muslim' ]
}, {
    count: '41',
    arabic: 'سُبْحَنَ',
    transliteration: 'subhaana',
    translations: [ 'glory' ]
}, {
    count: '41',
    arabic: 'عَمَل',
    transliteration: 'amal',
    translations: [ 'deed' ]
}, {
    count: '37',
    arabic: 'اَلْمُسْتَقِيْم',
    transliteration: 'al-mustaqeem',
    translations: [ 'the straight' ]
}, {
    count: '36',
    arabic: 'اَحّسَن',
    transliteration: 'ahsan',
    translations: [ 'best' ]
}, {
    count: '23',
    arabic: 'حَدِيْث',
    transliteration: 'hadees',
    translations: [ 'statement', 'story' ]
}, {
    count: '20',
    arabic: '?',
    transliteration: '?',
    translations: [ 'wisdom' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'deepskyblue' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const deepskyblue =  {
    number: `#${count.toLocaleString()} Religion ${percent}%`,
    words
}