const words = [{
    count: '1',
    arabic: 'نَعْبُدُ',
    transliteration: 'naʿbudu',
    translations: [ 'we worship' ]
}, {
    count: '1',
    arabic: 'نَسْتَعِينُ',
    transliteration: 'nastaʿīnu',
    translations: [ 'we ask for help' ]
}, {
    count: '1',
    arabic: 'ٱلْمَغْضُوبِ',
    transliteration: 'l-maghḍūbi',
    translations: [ 'those who earned (your) wrath' ]
}].map((a) => ({ ...a, color: 'white' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const white =  {
    number: `#${count.toLocaleString()} Distinct Words ${percent}%`,
    words
}