const words = [{
    count: '383',
    arabic: 'قَوْم',
    transliteration: 'yawm',
    translations: [ 'People', 'Nation' ]
}, {
    count: '293',
    arabic: 'نَفْس',
    transliteration: 'nafs',
    translations: [ 'soul' ],
}, {
    count: '241',
    arabic: 'لَنَّاسِ',
    transliteration: 'l-nāsi',
    translations: [ '(of) humankind', '(of) mankind' ],
}, {
    count: '125',
    arabic: 'عِبَاد',
    transliteration: 'ibaad',
    translations: [ 'slaves', 'servants' ],
}, {
    count: '65',
    arabic: 'اَلْاِنْسَان',
    transliteration: 'l-nāsi',
    translations: [ 'the humankind'],
}, { 
    count: '44',
    arabic: 'ضَدْد',
    transliteration: 'sadr',
    translations: [ 'chest' ]
}, { 
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'chartreuse' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((Number(count)/ 77_430) * 100).toPrecision(4)

export const chartreuse =  {
    number: `#${count.toLocaleString()} People ${percent}%`,
    words
}