
import { getChapter, getRead } from '../verse'

const verses = {
    '1:0': {
        number: 'Bukhari:3461',
        words: [{
            count: '?',
            arabic: 'بَلِّغُوْا',
            transliteration: '?',
            color: 'fuchsia',
            translations: [ 'Convey' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَنِّ&#x200d;<span style="color: cyan">&#x200d;يْ</span>',
            transliteration: '?',
            color: 'gold',
            translations: [ 'from <span style="color: cyan">me</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَوْ',
            transliteration: '?',
            color: 'gold',
            translations: [ 'even if' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اٰيَتً',
            transliteration: '?',
            color: 'deepskyblue',
            translations: [ '(it is a single) verse' ],
            punctuations: [ '', '.' ]
        }]
    },
}

export const statements = () => getChapter(verses)
export const statementsHome = () => getRead(verses)
