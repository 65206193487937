import { getChapter, getRead } from '../verse'

const verses = {
    '109:1': {
        number: '١',
        words: [{
            transliteration: 'qul',
            arabic: 'قُلْ',
            translations: [ 'Say' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'yāayyuhā',
            arabic: 'يَـٰٓأَيُّهَا',
            translations: [ 'O' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'l-kāfirūna',
            arabic: 'ٱلْكَـٰفِرُونَ',
            translations: [ 'disbelievers' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
    '109:2': {
        number: '٢',
        words: [{
            transliteration: 'lā',
            arabic: 'لَآ',
            translations: [ 'Not' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'aʿbudu',
            arabic: 'أَعْبُدُ',
            translations: [ 'I worship' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'mā',
            arabic: 'مَا',
            translations: [ 'what' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'taʿbudūna',
            arabic: 'تَعْبُدُونَ',
            translations: [ 'you worship' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
    '109:3': {
        number: '٣',
        words: [{
            transliteration: 'walā',
            arabic: 'وَلَآ',
            translations: [ 'And not' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'antum',
            arabic: 'أَنتُمْ',
            translations: [ 'you' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'ʿābidūna',
            arabic: 'عَـٰبِدُونَ',
            translations: [ '(are) worshippers' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'mā',
            arabic: 'مَآ',
            translations: [ '(of) what' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'aʿbudu',
            arabic: 'أَعْبُدُ',
            translations: [ 'I worship' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
    '109:4': {
        number: '٤',
        words: [{
            transliteration: 'walā',
            arabic: 'وَلَآ',
            translations: [ 'And not' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'anā',
            arabic: 'أَنَا۠',
            translations: [ 'I am' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'ʿābidun',
            arabic: 'عَابِدٌ',
            translations: [ 'a worshipper' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'mā',
            arabic: 'مَّا',
            translations: [ '(of) what' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'ʿabadttum',
            arabic: 'عَبَدتُّمْ',
            translations: [ 'you worship' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
    '109:5': {
        number: '٥',
        words: [{
            transliteration: 'walā',
            arabic: 'وَلَآ',
            translations: [ 'And not' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'antum',
            arabic: 'أَنتُمْ',
            translations: [ 'you' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'ʿābidūna',
            arabic: 'عَـٰبِدُونَ',
            translations: [ '(are) worshippers' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'mā',
            arabic: 'مَآ',
            translations: [ '(of) what' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'aʿbudu',
            arabic: 'أَعْبُدُ',
            translations: [ 'I worship' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
    '109:6': {
        number: '٦',
        words: [{
            transliteration: 'lakum',
            arabic: 'لَكُمْ',
            translations: [ 'For you' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'dīnukum',
            arabic: 'دِينُكُمْ',
            translations: [ '(is) your religion' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'waliya',
            arabic: 'وَلِىَ',
            translations: [ 'and for me' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }, {
            transliteration: 'dīni',
            arabic: 'دِينِ',
            translations: [ '(is) my religion' ],
            color: 'white',
            count: '?',
            punctuations: [ '', '' ],
        }]
    },
}

export const chapter109 = () => getChapter(verses)

export const chapter109Read = () => getRead(verses)