export const mosque = {
    'm1': {
        number: 'Enter Mosque',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'افْتَحْ',
            transliteration: 'aftah',
            color: 'white',
            translations: [ 'open' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِىْ',
            transliteration: 'lia',
            color: 'white',
            translations: [ 'for me' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَبْوَ ابَ',
            transliteration: 'aabw aba',
            color: 'white',
            translations: [ 'door' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَحْمَتِكَى',
            transliteration: 'rahmatikaa',
            color: 'white',
            translations: [ 'your blessings' ],
            punctuations: [ '', '' ]
        }]
    },
    'm2': {
        number: 'Leaving Mosque',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِنِّى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَسْئَاُكَى',
            transliteration: 'aasyaaukaa',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْ',
            transliteration: 'min',
            color: 'white',
            translations: [ 'from' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فَضْلِكَى',
            transliteration: 'fadlikaa',
            color: 'white',
            translations: [ 'please' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَرَحْمَتِكَى',
            transliteration: 'warahmatikaa',
            color: 'white',
            translations: [ 'and your mercy' ],
            punctuations: [ '', '' ]
        }]
    },
};