
import Container from 'react-bootstrap/esm/Container';

import { colors } from './colorWords'


const title = (chapterTitle) => (<div>
    <h2>{chapterTitle}</h2>
    <br></br>
</div>)

function Legend() {
    return (
      <div className="App ">
        <header className="App-header">
            <Container>
              { title('Color Legend') }
              { colors() }
            </Container>
        </header>
      </div>
    );
  }
  
  export default Legend;
  