export const sight = {
    's1': {
        number: 'Seeing Mirror',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كَمَا',
            transliteration: 'kama',
            color: 'white',
            translations: [ 'as' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَسَّنْتَ',
            transliteration: 'hasant',
            color: 'white',
            translations: [ 'Well done' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'خَلَقِىْ',
            transliteration: 'khuluqia',
            color: 'white',
            translations: [ 'congenital' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فَحَسِّنْ',
            transliteration: 'fahasin',
            color: 'white',
            translations: [ 'so improve' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'خُلُقِىْ',
            transliteration: 'khuluqia',
            color: 'white',
            translations: [ 'congenital' ],
            punctuations: [ '', '' ]
        }]
    },
    's2': {
        number: 'Seeing Moon',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَهِاَّهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَيْنَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِالْيُمْنِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْاِيْمَانِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالسَّلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَةِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْاِسْلَامِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالتَّوْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فِيْقِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِمَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تُحِبُّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَتَرْضٰى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَبِّىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَبُّكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
};