import 'bootstrap/dist/css/bootstrap.css';

import Row from 'react-bootstrap/Row'

export const span = (word, color) => (<span style={{ color }}>
    <span dangerouslySetInnerHTML={{ __html: word }}></span>
</span>)

export const rowH6 = (word, color) => (<Row><h6>{ span(word, color) }</h6></Row>)

export const rowH1 = (word, color) => (<Row><h1>{ span(word, color) }</h1></Row>)

export const rowH5 = (word, color) => (<Row><h5>{ span(word, color) }</h5></Row>)