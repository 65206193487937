import { getChapter, getRead } from '../verse'

const verses = {
    '1:0': {
        number: '٠',
        words: [{
            count: '7',
            arabic: 'أَعُوذُ',
            transliteration: 'aʿūdhu',
            color: 'fuchsia',
            translations: [ 'I seek refuge', 'I seek protection'],
            punctuations: [ '', '' ]
        }, {
            count: '2550?',
            arabic: 'بِآالّٰهِ',
            transliteration: 'bil-lahi',
            color: 'skyblue',
            translations: [ 'in God', 'in Allah' ],
            punctuations: [ '', '' ]
        }, {
            count: '2471',
            arabic: 'مِنْ',
            transliteration: 'mina',
            color: 'hotpink',
            translations: [ 'from' ],
            punctuations: [ '', '' ]
        }, {
            count: '88',
            arabic: 'التَّيْطٰنِ',
            transliteration: 'shaytan',
            color: 'red',
            translations: [ 'Satan' ],
            punctuations: [ '', '' ]
        }, {
            count: '6',
            arabic: 'الرَّجِيْمِ',
            transliteration: 'nirajim',
            color: 'red',
            translations: [ 'the outcast' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:1': {
        number: '١',
        words: [{
            count: '<span style="color: lightsalmon">39</span>',
            arabic: 'بِ&#x200d;<span style="color: lightsalmon">&#x200d;سْمِ</span>',
            transliteration: 'bi<span style="color: lightsalmon">s\'mi</span>',
            color: 'gold',
            translations: [ 'In <span style="color: lightsalmon">(the) name</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '2550?',
            arabic: 'ٱللَّهِ',
            transliteration: 'l-lahi',
            color: 'skyblue',
            translations: [ '(of) God', '(of) Allah' ],
            punctuations: [ '', ',' ]
        }, {
            count: '57',
            arabic: 'ٱلرَّحْمَـٰنِ',
            transliteration: 'l-raḥmāni',
            color: 'royalblue',
            translations: [ 'the most compassionate' ],
            punctuations: [ '', ',' ]
        }, {
            count: '115',
            arabic: 'ٱلرَّحِيمِ',
            transliteration: 'l-raḥīmi',
            color: 'royalblue',
            translations: [ 'the most merciful' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:2': {
        number: '٢',
        words: [{
            count: '43',
            arabic: 'ٱلْحَمْدُ',
            transliteration: 'al-ḥamdu',
            color: 'fuchsia',
            translations: [ 'All praises' ],
            punctuations: [ '', '' ]
        }, {
            count: '149',
            arabic: 'لِلَّهِ',
            transliteration: 'lillahi',
            color: 'skyblue',
            translations: [ '(is due) to God', '(be) to Allah' ],
            punctuations: [ '', '' ]
        }, {
            count: '199?',
            arabic: 'رَبِّ',
            transliteration: 'rabbi',
            color: 'royalblue',
            translations: [ '(the) Lord' ],
            punctuations: [ '', '' ]
        }, {
            count: '73',
            arabic: 'ٱلْعَـٰلَمِينَ',
            transliteration: 'l-ʿālamīna',
            color: 'deepskyblue',
            translations: [ 'of all worlds', 'of the universe' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:3': {
        number: '٣',
        words: [{
            count: '57',
            arabic: 'ٱلرَّحْمَـٰنِ',
            transliteration: 'al-raḥmāni',
            color: 'royalblue',
            translations: [ 'The most compassionate' ],
            punctuations: [ '', ',' ]
        }, {
            count: '115',
            arabic: 'ٱلرَّحِيمِ',
            transliteration: 'l-raḥīmi',
            color: 'royalblue', 
            translations: [ 'the most merciful' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:4': {
        number: '٤',
        words: [{
            count: '3',
            arabic: 'مَـٰلِكِ',
            transliteration: 'māliki',
            color: 'royalblue',
            translations: [ '(The) master', '(The) king' ],
            punctuations: [ '', '' ]
        }, {
            count: '405',
            arabic: 'يَوْمِ',
            transliteration: 'yawmi',
            color: 'deepskyblue', 
            translations: [ '(of the) day', 'day' ],
            punctuations: [ '', '' ]
        }, {
            count: '92',
            arabic: 'ٱلدِّينِ',
            transliteration: 'l-dīni',
            color: 'deepskyblue', 
            translations: [ '(of) judgment', '(of) recompense' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:5': {
        number: '٥',
        words: [{
            count: '24',
            arabic: 'إِيَّاكَ',
            transliteration: 'iyyāka',
            color: 'coral',
            translations: [ 'You alone' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'نَعْبُدُ',
            transliteration: 'naʿbudu',
            color: 'white', 
            translations: [ 'we worship' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: coral">24</span>',
            arabic: 'وَ&#x200d;<span style="color: coral">&#x200d;إِيَّاكَ</span>',
            transliteration: 'wa-<span style="color: coral">iyyāka</span>',
            color: 'gold', 
            translations: [ 'and <span style="color: coral">you alone</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'نَسْتَعِينُ',
            transliteration: 'nastaʿīnu',
            color: 'white', 
            translations: [ 'we ask for help' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:6': {
        number: '٦',
        words: [{
            count: '2',
            arabic: 'ٱهْدِنَا',
            transliteration: 'ih\'dinā',
            color: 'fuchsia',
            translations: [ 'Guide us' ],
            punctuations: [ '', '' ]
        }, {
            count: '45',
            arabic: 'ٱلصِّرَٰطَ',
            transliteration: 'l-ṣirāṭa',
            color: 'dodgerblue', 
            translations: [ '(to) the path' ],
            punctuations: [ '', '' ]
        }, {
            count: '37',
            arabic: 'ٱلْمُسْتَقِيمَ',
            transliteration: 'l-mus\'taqīma',
            color: 'dodgerblue', 
            translations: [ 'the straight' ],
            punctuations: [ '', ',' ]
        }]
    },
    '1:7a': {
        number: '٧a',
        words: [{
            count: '45',
            arabic: 'صِرَٰطَ',
            transliteration: 'ṣirāṭa',
            color: 'dodgerblue',
            translations: [ '(The) path' ],
            punctuations: [ '', '' ]
        }, {
            count: '1080',
            arabic: 'ٱلَّذِينَ',
            transliteration: 'alladhīna',
            color: 'cyan', 
            translations: [ '(of) those' ],
            punctuations: [ '', '' ]
        }, {
            count: '5',
            arabic: 'أَنْعَمْتَ',
            transliteration: 'anʿamta',
            color: 'dodgerblue', 
            translations: [ 'you have bestowed (your) favors', 'you have blessed (your) favors',  ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: cyan">216</span>',
            arabic: 'عَلَيْ&#x200d;<span style="color: cyan">&#x200d;هِمْ</span>',
            transliteration: 'ʿalay<span style="color: cyan">him</span>',
            color: 'gold', 
            translations: [ 'on <span style="color: cyan">themselves</span>', 'on <span style="color: cyan">them</span>' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:7b': {
        number: '٧b',
        words: [{
            count: '147',
            arabic: 'غَيْرِ',
            transliteration: 'ghayri',
            color: 'fuchsia', 
            translations: [ 'not (of)' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'ٱلْمَغْضُوبِ',
            transliteration: 'l-maghḍūbi',
            color: 'white', 
            translations: [ 'those who earned (your) wrath', 'those who earned (your) anger' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: cyan">216</span>',
            arabic: 'عَلَيْ&#x200d;<span style="color: cyan">&#x200d;هِمْ</span>',
            transliteration: 'ʿalay<span style="color: cyan">him</span>',
            color: 'gold', 
            translations: [ 'on <span style="color: cyan">themselves</span>', 'on <span style="color: cyan">them</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '1687',
            arabic: 'وَلَا',
            transliteration: 'walā',
            color: 'gold', 
            translations: [ 'and not', 'nor' ],
            punctuations: [ '', '' ]
        }, {
            count: '14',
            arabic: 'ٱلضَّآلِّينَ',
            transliteration: 'l-ḍālīna',
            color: 'darkred', 
            translations: [ '(of) those who go astray' ],
            punctuations: [ '', '' ]
        }]
    }
}

export const chapter1 = () => getChapter(verses)
export const chapter1Read = () => getRead(verses)
