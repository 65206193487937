import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import Home from './home/Home';
import Study from './chapters/Study';
import Read from './chapters/Read';
import Legend from './legend/Legend';
import Error from './error/Error';
import Prayer from './prayers/Prayer';
import Statement from './purpose/Statement';
import Invocation from './invocation/Invocation';

const routes = [
    ['/', <Home />],
    ['legend', <Legend />],
    ['quran/read', <Read />],
    ['salah/study', <Prayer />],
    ['invocation/statement', <Invocation />],
    ['purpose/statement', <Statement />],
    ['quran/study', <Study />],
    ['*', <Error />],
]

const getRoutes = () => (<Routes>
    { routes.map(([path, comp]) => <Route path={ path } element={ comp }></Route>) }
</Routes>)

export const router = () => (<BrowserRouter>{ getRoutes() }</BrowserRouter>)