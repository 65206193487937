export const date = {
    'd1': {
        number: 'Finish Quran',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اٰنِسْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَحْشَتِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فِيْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'قَبْرِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُمَّارْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَمْنِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِالْقُرْاٰنِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَظِيْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاجْعَلْهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِٰىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِمَامًا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّنُوْرًا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّهُدًى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّرَحْمَةً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ذَكِّرْنِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'نَسِيْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَعَلِّمْنِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'جَهِلْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَارْزُقْنِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تِلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَتَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اٰنآءَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّيْلِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاٰنآءَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'النَّهَارِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاجْعَلْهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حُجَّةً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَّارَبَّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'd2': {
        number: 'iman-e-mujmal',
        words: [{
            count: '?',
            arabic: 'اٰمَنْتُ',
            transliteration: 'aamantu',
            color: 'white',
            translations: [ 'I have faith' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِاللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'in God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كَمَا',
            transliteration: 'kama',
            color: 'white',
            translations: [ 'as' ],
            punctuations: [ '', '' ]

        }, {
            count: '?',
            arabic: 'هُوَ',
            transliteration: 'huwa',
            color: 'white',
            translations: [ 'he' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِاَسْمَآئِهٖ',
            transliteration: 'biaasmayihٖ',
            color: 'white',
            translations: [ 'is known by his name' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَسِفَاتِهٖ',
            transliteration: 'wasifatihٖ',
            color: 'white',
            translations: [ 'and attributes' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَقَبِلْتُ',
            transliteration: 'waqabilt',
            color: 'white',
            translations: [ 'and I accept' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'جَمِيْعَ',
            transliteration: 'jamie',
            color: 'white',
            translations: [ 'all' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَحْكَا',
            transliteration: 'aahka',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِهٰٖ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِقْرَ ارٌمْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِا لِّلسَانِ ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَتَسْدِيْقٌمْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِالْقاْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'd3': {
        number: 'iman-e-mufasal',
        words: [{
            count: '?',
            arabic: 'اٰمَنْتُ',
            transliteration: 'aamantu',
            color: 'white',
            translations: [ 'I have faith' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِاللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'in God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَمَلٰٰئِكَتِهٖ',
            transliteration: 'wamalyikatihٖ',
            color: 'white',
            translations: [ 'and his angles' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَكُتُبِهٖ',
            transliteration: 'wakutubihٖ',
            color: 'white',
            translations: [ 'and his books' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَرُسُلِهٖ',
            transliteration: 'warusulihٖ',
            color: 'white',
            translations: [ 'and his messengers' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْيَوْمِالْاٰ خِرِ',
            transliteration: 'walyawm',
            color: 'white',
            translations: [ 'and the day of judgement' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْقَدْرِ',
            transliteration: 'walqadr',
            color: 'white',
            translations: [ 'and fate' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'خَيْرِهٖ',
            transliteration: 'khayrihٖ',
            color: 'white',
            translations: [ 'his goodness' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَشَرِّهٖ',
            transliteration: 'washarihٖ',
            color: 'white',
            translations: [ 'and his evil' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَ',
            transliteration: 'mina',
            color: 'white',
            translations: [ 'from' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تَعَالٰى',
            transliteration: 'taeala',
            color: 'white',
            translations: [ 'come here' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْبَعْثِ',
            transliteration: 'walbaeth',
            color: 'white',
            translations: [ 'and the resurrection' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بَعْدَ',
            transliteration: 'baeda',
            color: 'white',
            translations: [ 'after' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْمَوْتِ',
            transliteration: 'almawt',
            color: 'white',
            translations: [ 'death' ],
            punctuations: [ '', '' ]
        }]
    },
    'd4': {
        number: 'shab-e-qarae',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِنَّكَى',
            transliteration: '.',
            color: 'white',
            translations: [ 'the smartest' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَفُوٌّ',
            transliteration: 'eafuun',
            color: 'white',
            translations: [ 'pardon' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تُحِبُّ',
            transliteration: 'tuhibu',
            color: 'white',
            translations: [ 'Love' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَفْوَ',
            transliteration: 'aleafwa',
            color: 'white',
            translations: [ 'pardon' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فَاعْفُ',
            transliteration: 'faef',
            color: 'white',
            translations: [ 'forgive' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَنِّى',
            transliteration: '.',
            color: 'white',
            translations: [ 'About Me' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَاغَفُوْرُ',
            transliteration: 'yaghafur',
            color: 'white',
            translations: [ 'You are forgiving' ],
            punctuations: [ '', '' ]
        }]
    },
}