const words = [{
    count: '405',
    arabic: 'يِلوْم',
    transliteration: 'yawm',
    translations: [ 'day' ]
}, {
    count: '92',
    arabic: 'اَلدِّيْن',
    transliteration: 'ad-deen',
    translations: [ 'the Judgment' ]

}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]


}, {
    count: '34',
    arabic: '?',
    transliteration: '?',
    translations: [ 'companion', 'fellow' ]
}, {
    count: '147',
    arabic: '?',
    transliteration: '?',
    translations: [ 'garden' ]
}, {
    count: '13',
    arabic: '?',
    transliteration: '?',
    translations: [ 'reward' ]
}, {
    count: '17',
    arabic: '?',
    transliteration: '?',
    translations: [ 'of bliss & delight', 'inticpation?' ]
}, {
    count: '28',
    arabic: '?',
    transliteration: '?',
    translations: [ 'forever', 'ever' ]
}, {
    count: '54',
    arabic: '?',
    transliteration: '?',
    translations: [ 'river' ]
}, {
    count: '115',
    arabic: '?',
    transliteration: '?',
    translations: [ 'the hereafter' ]
}, {
    count: '70',
    arabic: '?',
    transliteration: '?',
    translations: [ 'resurrection' ]
}, {
    count: '47',
    arabic: '?',
    transliteration: '?',
    translations: [ 'hour (day of resurrection)' ]
}, {
    count: '405',
    arabic: '?',
    transliteration: '?',
    translations: [ 'day' ]
}, {
    count: '65',
    arabic: '?',
    transliteration: '?',
    translations: [ 'that day' ]
}, {
    count: '53',
    arabic: '?',
    transliteration: '?',
    translations: [ 'term' ]
}, {
    count: '21',
    arabic: '?',
    transliteration: '?',
    translations: [ 'fixed' ]
}, {
    count: '28',
    arabic: '?',
    transliteration: '?',
    translations: [ 'destination' ]
}, {
    count: '22',
    arabic: '?',
    transliteration: '?',
    translations: [ 'refuge', 'abode' ]
}, {
    count: '105',
    arabic: '?',
    transliteration: '?',
    translations: [ 'compensation', 'reward' ]
}, {
    count: '42',
    arabic: '?',
    transliteration: '?',
    translations: [ 'reward' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
    
}, {
    count: '32',
    arabic: '?',
    transliteration: '?',
    translations: [ 'end' ]
}].map((a) => ({ ...a, color: 'cadetblue' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((Number(count)/ 77_430) * 100).toPrecision(4)

export const cadetblue =  {
    number: `#${count.toLocaleString()} Last Day ${percent}%`,
    words
}