
import { getChapter, getRead } from '../verse'

const verses = {
    'a1': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }]
    },
}

export const statements = () => getChapter(verses)
export const prayerHome = () => getRead(verses)
