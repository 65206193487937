import { getChapter, getRead } from '../verse'

const verses = {
    '103:1': {
        number: '١',
        words: [{
            count: '<span style="color: white">1</span>',
            arabic: 'وَ&#x200d;<span style="color: white">&#x200d;ٱلْعَصْرِ</span>',
            transliteration: 'wa<span style="color: white">l-ʿaṣri</span>',
            color: 'gold',
            translations: [ 'By <span style="color: white">(the passage of) time</span>' ],
            punctuations: [ '', ',' ]
        }]
    },
    '103:2': {
        number: '٢',
        words: [{
            count: '1534',
            arabic: 'إِنَّ',
            transliteration: 'inna',
            color: 'gold',
            translations: [ 'Indeed' ],
            punctuations: [ '', ',' ]
        }, {
            count: '65',
            arabic: 'ٱلْإِنسَـٰنَ',
            transliteration: 'l-insāna',
            color: 'chartreuse',
            translations: [ 'humankind', 'mankind' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَفِى',
            transliteration: 'lafī',
            color: 'gold',
            translations: [ '(is) surely in', '(is) in' ],
            punctuations: [ '', '' ]
        }, {
            count: '2',
            arabic: 'خُسْرٍ',
            transliteration: 'khus\'rin',
            color: 'red',
            translations: [ '(grave) loss','loss' ],
            punctuations: [ '', ',' ]
        }]
    },
    '103:3a': {
        number: '٣a',
        words: [{
            count: '664',
            arabic: 'إِلَّا',
            transliteration: 'illā',
            color: 'gold',
            translations: [ 'except' ],
            punctuations: [ '', '' ]
        }, {
            count: '1080',
            arabic: 'ٱلَّذِينَ',
            transliteration: 'alladhīna',
            color: 'cyan', 
            translations: [ 'those who' ],
            punctuations: [ '', '' ]
        }, {
            count: '258',
            arabic: 'ءَامَنُوا۟',
            transliteration: 'āmanū',
            color: 'lightcyan',
            translations: [ 'believe', 'faith' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: hotpink">?</span>',
            arabic: 'وَ&#x200d;<span style="color: hotpink">&#x200d;عَمِلُوا۟</span>',
            transliteration: 'wa<span style="color: hotpink">ʿamilū</span>',
            color: 'gold',
            translations: [ 'and <span style="color: hotpink">do</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '125?',
            arabic: 'ٱلصَّـٰلِحَـٰتِ',
            transliteration: 'l-ṣāliḥāti',
            color: 'lightcyan',
            translations: [ 'righteous deeds' ],
            punctuations: [ '', '' ]
        }]
    },
    '103:3b': {
        number: '٣b',
        words: [{
            count: '<span style="color: fuchsia">258</span>',
            arabic: 'وَ&#x200d;<span style="color: fuchsia">&#x200d;تَوَاصَوْا۟</span>',
            transliteration: 'wa<span style="color: fuchsia">tawāṣaw</span>',
            color: 'gold',
            translations: [ 'and <span style="color: fuchsia">urge (each other)</span>', 'and <span style="color: fuchsia">enjoin (each other)</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: lightcyan">247</span>',
            arabic: 'بِ&#x200d;<span style="color: lightcyan">&#x200d;ٱلْحَقِّ</span>',
            transliteration: 'bi<span style="color: lightcyan">l-ḥaqi</span>',
            color: 'gold',
            translations: [ 'to <span style="color: lightcyan">(the) truth</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: fuchsia">258</span>',
            arabic: 'وَ&#x200d;<span style="color: fuchsia">&#x200d;تَوَاصَوْا۟</span>',
            transliteration: 'wa<span style="color: fuchsia">tawāṣaw</span>',
            color: 'gold',
            translations: [ 'and <span style="color: fuchsia">urge (each other)</span>', 'and <span style="color: fuchsia">enjoin (each other)</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: lightcyan">15?</span>',
            arabic: 'بِ&#x200d;<span style="color: lightcyan">&#x200d;ٱلصَّبْرِ</span>',
            transliteration: 'bi<span style="color: lightcyan">l-ṣabri</span>',
            color: 'gold',
            translations: [ 'to <span style="color: lightcyan">patience</span>', 'to <span style="color: lightcyan">perseverance</span>' ],
            punctuations: [ '', '.' ]
        }]
    },
    
}

export const chapter103 = () => getChapter(verses)

export const chapter103Read = () => getRead(verses)