const words =  [{
    count: '831',
    arabic: 'مَنْ',
    transliteration: 'man',
    translations: [ 'who' ]
}, {
    count: '239',
    arabic: 'اِذْ',
    transliteration: 'iz',
    translations: [ 'when' ]
}, {
    count: '239',
    arabic: 'اِذَا',
    transliteration: 'izaa',
    translations: [ 'when' ]
}, {
    count: '59',
    arabic: 'اَيٌّ',
    transliteration: 'ayyum',
    translations: [ 'which' ]
}, {
    count: '27',
    arabic: 'مَاذَا',
    transliteration: 'maaza',
    translations: [ 'what' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'mediumspringgreen' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const mediumspringgreen =  {
    number: `#${count.toLocaleString()} Questions ${percent}%`,
    words
}