export const lifestyle = {
    'l1': {
        number: 'Enter Bathroom',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِنِّى',
            transliteration: '.',
            color: 'white',
            translations: [ 'that I' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَعُوْذُبِكَى',
            transliteration: '.',
            color: 'white',
            translations: [ 'I seek refuge' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَ',
            transliteration: 'mina.',
            color: 'white',
            translations: [ 'from' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْخُبُثِ',
            transliteration: 'alkhubuth',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْخَبَائِثْ',
            transliteration: 'walkhabayith',
            color: 'white',
            translations: [ 'and the wicked' ],
            punctuations: [ '', '' ]
        }]
    },
    'l2': {
        number: 'Leave Bathroom',
        words: [{
            count: '?',
            arabic: 'اَلْحَمْدُ',
            transliteration: 'aalhamdu',
            color: 'white',
            translations: [ 'All praise blongs' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِلّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'to God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَذْهَبَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَنِّىْ الْاَذٰى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَعَافَانِىْ',
            transliteration: 'waeafania',
            color: 'white',
            translations: [ 'and healed me' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'غُفْرَانَكَى ',
            transliteration: 'ghufranakaa',
            color: 'white',
            translations: [ 'forgive me' ],
            punctuations: [ '', '' ]
        }]
    },
    'l3': {
        number: 'Change Outfit',
        words: [{
            count: '?',
            arabic: 'اَلْحَمْدُ',
            transliteration: 'aalhamdu',
            color: 'white',
            translations: [ 'All praise belongs' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِلّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'to God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْ',
            transliteration: 'aladhia',
            color: 'white',
            translations: [ 'that' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كَسَانِىْ',
            transliteration: 'kasania',
            color: 'white',
            translations: [ 'clothe me' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَآ',
            transliteration: 'ma',
            color: 'white',
            translations: [ 'what' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اُوَارِىَ',
            transliteration: 'auaria',
            color: 'white',
            translations: [ 'hide' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِهٖ',
            transliteration: 'bihe',
            color: 'white',
            translations: [ 'with it' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَوْرَتِىْ',
            transliteration: 'eawritia',
            color: 'white',
            translations: [ 'Im naked' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَتَجَمَّلُ',
            transliteration: 'waatajamal',
            color: 'white',
            translations: [ 'and beautify' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِهٖ',
            transliteration: 'bihe',
            color: 'white',
            translations: [ 'with it' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فِىْ',
            transliteration: 'fia',
            color: 'white',
            translations: [ 'in' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَيَاتِىْ',
            transliteration: 'hayatia',
            color: 'white',
            translations: [ 'my life' ],
            punctuations: [ '', '' ]
        }]
    },
    'l4': {
        number: 'Leaving House',
        words: [{
            count: '?',
            arabic: 'بِسْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'ّIn the name of' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تَوَكَّلْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }]
    },
};