
import { getChapter, getRead } from '../verse'

import { kalimas } from './kalima'
import { resurection } from './resurection'
import { date } from './date'
import { sight } from './sight'
import { food } from './food'
import { lifestyle } from './lifestyle'
import { mosque } from './mosque'

const verses = {
    ...kalimas,
    ...resurection,
    ...mosque,
    ...date,
    ...sight,
    ...food,
    ...lifestyle,
}

export const statements = () => getChapter(verses)
export const statementsRead = () => getRead(verses)
