
import { getChapter, getRead } from '../verse'

const verses = {
    '1:0': {
        number: 'Kalima Tayyab',
        words: [{
            count: '?',
            arabic: 'لَا',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إلَٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إلَّااللَّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مُحَمَّدٌ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَّسُوْلُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللَّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
}

export const statements = () => getChapter(verses)
export const invocationsHome = () => getRead(verses)
