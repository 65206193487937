export const resurection = {
    'r1': {
        number: 'Before Sleep',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِاِسْمِكَى',
            transliteration: 'biaismikaa',
            color: 'white',
            translations: [ 'in your name' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَمُوْتُ',
            transliteration: 'aamut',
            color: 'white',
            translations: [ 'I die' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَحْيَا',
            transliteration: 'waahya',
            color: 'white',
            translations: [ 'and live' ],
            punctuations: [ '', '' ]
        }]
    },
    'r2': {
        number: 'Wake up',
        words: [{
            count: '?',
            arabic: 'اَلْحَمْدُ',
            transliteration: 'All praise blongs',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِلّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'to God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْ',
            transliteration: 'aladhia',
            color: 'white',
            translations: [ 'that' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَحْيَانَا',
            transliteration: 'aahtana',
            color: 'white',
            translations: [ 'he took us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بَعْدَ',
            transliteration: 'baed',
            color: 'white',
            translations: [ 'after' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَآ',
            transliteration: 'ma',
            color: 'white',
            translations: [ 'what' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَمَاتَنَا',
            transliteration: 'aamatana',
            color: 'white',
            translations: [ 'our dead' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاِلَيْهِ',
            transliteration: 'wailayh',
            color: 'white',
            translations: [ 'and to him' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'النُّشُوْرُ',
            transliteration: 'alnushur',
            color: 'white',
            translations: [ 'resurrected' ],
            punctuations: [ '', '' ]
        }]
    },
    'r3': {
        number: 'Enter Graveyard',
        words: [{
            count: '?',
            arabic: 'اَلسَّلَامُ',
            transliteration: '.',
            color: 'white',
            translations: [ 'peace' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَيْكُمْ',
            transliteration: 'ealaykum',
            color: 'white',
            translations: [ 'on you' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَآ',
            transliteration: 'ya',
            color: 'white',
            translations: [ 'hey' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَهْلَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْقُبُوْرِ',
            transliteration: 'alqubur',
            color: 'white',
            translations: [ 'the graves' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَغْفِرُ',
            transliteration: 'yaghfir',
            color: 'white',
            translations: [ 'he forgives' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَنَا',
            transliteration: 'lana',
            color: 'white',
            translations: [ 'for us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَكُمْ',
            transliteration: 'walakum',
            color: 'white',
            translations: [ 'And to you' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَنْتُمْ',
            transliteration: 'waantum',
            color: 'white',
            translations: [ 'And you' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'سَلَفُنَا',
            transliteration: 'salafuna',
            color: 'white',
            translations: [ 'our ancestors' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَنَحْنُ',
            transliteration: 'wanahn',
            color: 'white',
            translations: [ 'And we' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِالْاَثَرِ',
            transliteration: 'bialaathar',
            color: 'white',
            translations: [ 'with effect' ],
            punctuations: [ '', '' ]
        }]
    },
    'r4': {
        number: 'Funeral',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اغْفِرْ',
            transliteration: 'aghfir',
            color: 'white',
            translations: [ 'forgive' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِحَيِّنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'our living' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَمَيِّتِنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and our deceased' ],
            punctuations: [ '', '.' ]
        }, {
            count: '?',
            arabic: 'وَشَاهِدِنَا',
            transliteration: 'washahidina',
            color: 'white',
            translations: [ 'And our present' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَغَآءٍبِنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and the absent' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَسَغِيْرِنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and our young' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَكَبِيْرِنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and our old' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَزَكَرِنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and our male' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاُنْثٰنَا',
            transliteration: '.',
            color: 'white',
            translations: [ 'and females' ],
            punctuations: [ '', '.' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ 'O God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَنْ',
            transliteration: '.',
            color: 'white',
            translations: [ 'whoever' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَحْيَيْتَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ 'you keep alive' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَّا',
            transliteration: '.',
            color: 'white',
            translations: [ 'of us' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فَاَحْيِهٖ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَاَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْاِسْلَامِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'Islam' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَمَنْ',
            transliteration: '.',
            color: 'white',
            translations: [ 'and from' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تَوَفَّيْتَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'فَتَوَفَّهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَى',
            transliteration: '.',
            color: 'white',
            translations: [ 'on' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْاِيْمَانِ',
            transliteration: '.',
            color: 'white',
            translations: [ 'faith' ],
            punctuations: [ '', '' ]
        }]
    },
};