const words = [{
    count: '2699',
    arabic: 'اَللَّه',
    transliteration: '\'al-lah',
    translations: [ 'God' ]
}, {
    count: '1464',
    arabic: 'اَلَّذِى',
    transliteration: 'al-lathy',
    translations: [ 'The one' ]
}, {
    count: '971',
    arabic: 'رَبِّ',
    transliteration: 'rabbi',
    translations: [ 'lord', 'sustainer' ]
}, {
    count: '176',
    arabic: 'خَيْر',
    transliteration: 'khayr',
    translations: [ 'best' ]
}, {
    count: '149',
    arabic: 'لِلَّهِ',
    transliteration: 'll-laahi',
    translations: [ 'for God', 'to God' ]
}, {
    count: '145',
    arabic: 'اِلَه',
    transliteration: 'ilaah',
    translations: [ 'the God' ]
}, {
    count: '116',
    arabic: 'ٱلرَّحِيم',
    transliteration: 'l-raḥīm',
    translations: [ 'The most merciful' ]
}, {
    count: '114',
    arabic: 'رَحْمَة',
    transliteration: 'raḥmah',
    translations: [ 'the Mercy' ]
}, {
    count: '107',
    arabic: 'اَلْعَظِيْم',
    transliteration: 'Al-azeem',
    translations: [ 'The Great' ]
}, {
    count: '101',
    arabic: '?',
    transliteration: '?',
    translations: [ 'mighty' ]
}, {
    count: '97',
    arabic: '?',
    transliteration: '?',
    translations: [ 'wise' ]
}, {
    count: '91',
    arabic: '?',
    transliteration: '?',
    translations: [ 'most forgiving' ]
}, {
    count: '82',
    arabic: '?',
    transliteration: '?',
    translations: [ 'first' ]
}, {
    count: '74',
    arabic: 'اَحَد',
    transliteration: 'ahad',
    translations: [ 'the One' ]
}, {
    count: '66',
    arabic: '?',
    transliteration: '?',
    translations: [ 'other' ]
}, {
    count: '58',
    arabic: '?',
    transliteration: '?',
    translations: [ 'warner' ]
}, {
    count: '57',
    arabic: 'ٱلرَّحْمَـٰن',
    transliteration: 'l-raḥmān',
    translations: [ 'The most compassionate' ]
}, {
    count: '53',
    arabic: '?',
    transliteration: '?',
    translations: [ 'one who sees clearly' ]
}, {
    count: '47',
    arabic: '?',
    transliteration: '?',
    translations: [ 'one who listens' ]
}, {
    count: '45',
    arabic: '?',
    transliteration: '?',
    translations: [ 'knowing ever aware' ]
}, {
    count: '45',
    arabic: '?',
    transliteration: '?',
    translations: [ 'all powerful' ]
}, {
    count: '40',
    arabic: '?',
    transliteration: '?',
    translations: [ 'last' ]
}, {
    count: '27',
    arabic: 'اَلْكَرِيْم',
    transliteration: '\'al-kareem',
    translations: [ 'Generous' ]
}, {
    count: '91',
    arabic: 'غفُوْر',
    transliteration: 'gafoor',
    translations: [ 'of forgiving' ]
}, {
    count: '25',
    arabic: '?',
    transliteration: '?',
    translations: [ 'far' ]
}, {
    count: '24',
    arabic: '?',
    transliteration: '?',
    translations: [ 'strong helper' ]
}, {
    count: '24',
    arabic: '?',
    transliteration: '?',
    translations: [ 'one who takes care of a thing' ]
}, {
    count: '20',
    arabic: '?',
    transliteration: '?',
    translations: [ 'warm (friend)', 'boiling water' ]
}, {
    count: '17',
    arabic: '?',
    transliteration: '?',
    translations: [ 'praiseworthy' ]
}, {
    count: '15',
    arabic: '?',
    transliteration: '?',
    translations: [ 'forbearing' ]
}, {
    count: '14',
    arabic: '?',
    transliteration: '?',
    translations: [ 'trustworthy' ]
}, {
    count: '11',
    arabic: '?',
    transliteration: '?',
    translations: [ 'most forgiving', 'oft forgiving' ]
}, {
    count: '10',
    arabic: '?',
    transliteration: '?',
    translations: [ 'grateful', 'appreciative' ]
}, {
    count: '0',
    arabic: 'a',
    transliteration: '؟',
    translations: [ '؟' ]
}].map((a) => ({ ...a, color: 'skyblue' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)


export const skyblue =  {
    number: `#${count.toLocaleString()} Attributes of God ${percent}%`,
    words
}