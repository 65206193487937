export const kalimas = {
    'k1': {
        number: 'Kalima Tayyab',
        words: [{
            count: '?',
            arabic: 'لَا',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إلَٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إلَّااللَّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مُحَمَّدٌ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَّسُوْلُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللَّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'k2': {
        number: 'Kalima 2',
        words: [{
            count: '?',
            arabic: 'اَشْهَدُ',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَنْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'لَّآاِلٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَّااللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَحْدَهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'شَرِيْكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَشْهَدُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَنَّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مُحَمَّدً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اعَبْدُهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَرَسُوْلُهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'k3': {
        number: 'Kalima 3',
        words: [{
            count: '?',
            arabic: 'سُبْحَانَ',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'وَالْحَمْدُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِلّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَكْبَرُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَوْلَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'قُوَّةَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِالّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَلِىِّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَظِيْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'k4': {
        number: 'Kalima 4',
        words: [{
            count: '?',
            arabic: 'لَآ',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'اِلَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'الّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَحْدَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'شَرِيْكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْمُلْكُى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْحَمْدُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يُحْىٖ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَيُمِيْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَهُوَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَىٌّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَمُوْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَبَدًا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَبَدًا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ذُو',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْجَلَالِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْاِرَامِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِيَدِهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْخَيْرُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَهُوَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلٰى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كُلِّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'شَىْءٍ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'قَدِيْرٌ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'k5': {
        number: 'Kalima 5',
        words: [{
            count: '?',
            arabic: 'اَسْتَغْفِرُ',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الّٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'رَبِّىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كُلِّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ذَمْيٍ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَذّنَبْتُهٗ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَمَدًا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَوْخَطَأً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'سِرًّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَوْعَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'نِيَةً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّاَتُوْبُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَيْهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الذَّمْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْٰ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اعْلَمُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَمِنَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الذَّمْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الَّذِىْٰ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَآ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَعْلَمُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِنَّكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَنْتَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَّامُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْغُيُوْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَسَتَّارُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعُيُوْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَغَفَّارُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الذُّنُوْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَوْلَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَلَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'قُوَّةَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِاللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَلِىِّ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْعَظِيْمِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
    'k6': {
        number: 'Kalima 6',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُمَّ',
            transliteration: '.',
            color: 'white',
            translations: [ '' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِنِّىْٰ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]            
        }, {
            count: '?',
            arabic: 'اَعُوْذُبِكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَنْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اُشْرِكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'شَيْأً',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّاَنَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَعّلَمُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِهٖ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَسْتَغْفِرُكَى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِمَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَآ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَعْلَمُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'بِهٖ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'تُبعتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَنْهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَتَبَرَّاْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِنَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْكُفْرِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالشِّرْكِى',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْكِذْبِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْغِيْبَةِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْبِدْعَةِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالنَّمِيْمَةِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْفَوَا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حِشِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْبُهْتَانِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَالْمَعَاسِىْ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كُلِّهَل',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَسْلَمْتُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَاَقُوْلُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَآ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلٰهَ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اِلَّا',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مُحَمَّدٌ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'رَّسُوْلُ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهِ',
            transliteration: '.',
            color: 'white',
            translations: [ '.' ],
            punctuations: [ '', '' ]
        }]
    },
}