import { getChapter, getRead } from '../verse'

const verses = {
    '113:1': {
        number: '١',
        words: [{
            count: '332',
            arabic: 'قُلْ',
            transliteration: 'qul',
            color: 'fuchsia',
            translations: [ 'Say', 'Say ˹O Prophet˺' ],
            punctuations: [ '', ',' ]
        }, {
            count: '7',
            arabic: 'أَعُوذُ',
            transliteration: 'aʿūdhu',
            color: 'fuchsia',
            translations: [ 'I seek refuge', 'I seek protection'],
            punctuations: [ '"', '' ]
        }, {
            count: '<span style="color: royalblue">199?</span>',
            arabic: 'بِ&#x200d;<span style="color: royalblue">&#x200d;رَبِّ</span>',
            transliteration: 'bi<span style="color: royalblue">rabbi</span>',
            color: 'gold',
            translations: [ 'in (the) <span style="color: royalblue">Lord</span>', ],
            punctuations: [ '', '']
        }, {
            count: '1',
            arabic: 'ٱلْفَلَقِ',
            transliteration: 'l-falaqi',
            color: 'white',
            translations: [ '(of) daybreak' ],
            punctuations: ['', ',' ]
        }]
    },
    '113:2': {
        number: '٢',
        words: [{
            count: '2471',
            arabic: 'مِن',
            transliteration: 'min',
            color: 'hotpink',
            translations: [ 'From' ],
            punctuations: [ '', '' ]
        }, {
            count: '30',
            arabic: 'شَرِّ',
            transliteration: 'sharri',
            color: 'red',
            translations: [ '(the) evil', '(the) mischief' ],
            punctuations: [ '', '' ]
        }, {
            count: '2154',
            arabic: 'مَا',
            transliteration: 'mā',
            color: 'hotpink',
            translations: [ '(of) what', '(of) that which' ],
            punctuations: [ '', '' ]
        }, {
            count: '150',
            arabic: 'خَلَقَ',
            transliteration: 'khalaqa',
            color: 'turquoise',
            translations: [ 'he created', 'he has created' ],
            punctuations: [ '', ',' ]
        }]
    },
    '113:3': {
        number: '٣',
        words: [{
            count: '<span style="color: hotpink">2471</span>',
            arabic: 'وَ&#x200d;<span style="color: hotpink">&#x200d;مِن</span>',
            transliteration: 'wa<span style="color: hotpink">wamin</span>',
            color: 'gold',
            translations: [ 'and <span style="color: hotpink">from</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '30',
            arabic: 'شَرِّ',
            transliteration: 'sharri',
            color: 'red',
            translations: [ '(the) evil', '(the) mischief' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'غَاسِقٍ  ',
            transliteration: 'ghāsiqin',
            color: 'white',
            translations: [ '(of) darkness', '(of the) night' ],
            punctuations: [ '', '' ]
        }, {
            count: '423',
            arabic: 'إِذَا',
            transliteration: 'idhā',
            color: 'hotpink',
            translations: [ 'when' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'وَقَبَ',
            transliteration: 'waqaba',
            color: 'white',
            translations: [ 'it settles', 'it spreads' ],
            punctuations: [ '', '' ]
            
        }]
    },
    '113:4': {
        number: '٤',
        words: [{
            count: '<span style="color: hotpink">2471</span>',
            arabic: 'وَ&#x200d;<span style="color: hotpink">&#x200d;مِن</span>',
            transliteration: 'wa<span style="color: hotpink">wamin</span>',
            color: 'gold',
            translations: [ 'and <span style="color: hotpink">from</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '30',
            arabic: 'شَرِّ',
            transliteration: 'sharri',
            color: 'red',
            translations: [ '(the) evil', '(the) mischief' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'ٱلنَّفَّـٰثَـٰتِ',
            transliteration: 'l-nafāthāti',
            color: 'white',
            translations: [ '(of) the blowers', '*type of black magic*' ],
            punctuations: [ '', '' ]
        }, {
            count: '1684',
            arabic: 'فِى',
            transliteration: 'fī',
            color: 'gold',
            translations: [ 'in', 'onto' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'ٱلْعُقَدِ',
            transliteration: 'l-ʿuqadi',
            color: 'white',
            translations: [ 'knots', 'the knots'],
            punctuations: [ '', ',' ]
        }]
    },
    '113:5': {
        number: '٥',
        words: [{
            count: '<span style="color: hotpink">2471</span>',
            arabic: 'وَ&#x200d;<span style="color: hotpink">&#x200d;مِن</span>',
            transliteration: 'wa<span style="color: hotpink">wamin</span>',
            color: 'gold',
            translations: [ 'and <span style="color: hotpink">from</span>' ],
            punctuations: [ '', '' ]
        }, {
            count: '30',
            arabic: 'شَرِّ',
            transliteration: 'sharri',
            color: 'red',
            translations: [ '(the) evil', '(the) mischief' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'حَاسِدٍ',
            transliteration: 'ḥāsidin',
            color: 'white',
            translations: [ '(of) an envier' ],
            punctuations: [ '', '' ]
        }, {
            count: '423',
            arabic: 'إِذَا',
            transliteration: 'idhā',
            color: 'hotpink',
            translations: [ 'when' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'حَسَدَ',
            transliteration: 'ḥasada',
            color: 'white',
            translations: [ 'they envies', 'he envies' ],
            punctuations: [ '', ',' ]
        }]
    },
}

export const chapter113 = () => getChapter(verses)
export const chapter113Read = () => getRead(verses)