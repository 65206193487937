const words = [{
//     count: '9240',
//     arabic: 'و',
//     transliteration: 'waa',
//     translations: [ 'and' ]
// }, {
//     count: '0',
//     arabic: 'وَلَوْ',
//     transliteration: 'waalaw',
//     translations: [ 'even if' ]
// }, {
    count: '3226',
    arabic: 'مِنْ',
    transliteration: 'min',
    translations: [ 'from', 'out of' ]
}, {
    count: '2177',
    arabic: 'مَا',
    transliteration: 'maa',
    translations: [ 'What', 'Not' ]
}, {
    count: '1701',
    arabic: 'فِىْ',
    transliteration: 'fee',
    translations: [ 'in', 'into' ]
}, {
    count: '1691',
    arabic: 'لَا',
    transliteration: 'laa',
    translations: [ 'not' ]
}, {
    count: '1682',
    arabic: 'إِنَّ',
    transliteration: '\'inna',
    translations: [ 'Indeed' ]
}, {
    count: '1445',
    arabic: 'ءَلَىٰ',
    transliteration: '\'alla',
    translations: [ 'on', 'opon' ]
}, {
    count: '1361',
    arabic: 'لِ',
    transliteration: 'li',
    translations: [ 'for' ]
}, {
    count: '742',
    arabic: 'إِلَىٰ',
    transliteration: '\'ilia',
    translations: [ 'to', 'towards' ]
}, {
    count: '697',
    arabic: 'إِنْ',
    transliteration: '\'in',
    translations: [ 'if' ]
}, {
    count: '663',
    arabic: 'إِلاَّ',
    transliteration: '\'illa',
    translations: [ 'except' ]
}, {
    count: '625',
    arabic: 'أَنْ',
    transliteration: 'ann',
    translations: [ 'to', 'that' ]
}, {
    count: '510',
    arabic: 'بِ',
    transliteration: 'bi',
    translations: [ 'in' ]
// 11
}, {
    count: '465',
    arabic: 'ءَنْ',
    transliteration: '\'an',
    translations: [ 'with' ]
}, {
    count: '461',
    arabic: 'يَا',
    transliteration: 'yaa',
    translations: [ 'o', 'oh' ]
}, {
    count: '454',
    arabic: 'إِذَا',
    transliteration: 'itha',
    translations: [ 'if' ]
}, {
    count: '406',
    arabic: 'لَقَدْ',
    transliteration: 'laqad',
    translations: [ 'certainly' ]
}, {
    count: '362',
    arabic: 'أَنَّ',
    transliteration: '\'an',
    translations: [ 'that' ]
}, {
    count: '353',
    arabic: 'لَمْ',
    transliteration: 'lam',
    translations: [ 'not' ]
}, {
    count: '338',
    arabic: 'ثُمَّ',
    transliteration: 'thuma',
    translations: [ 'then', 'afterwards' ]
}, {
    count: '280',
    arabic: 'اَوْ',
    transliteration: 'aw',
    translations: [ 'or' ]
}, {
    count: '239',
    arabic: 'إِذْ',
    transliteration: 'ith',
    translations: [ 'As', 'When' ]
}, {
    count: '200',
    arabic: 'لَوْ',
    transliteration: 'law',
    translations: [ 'if', 'if only' ]
}, {
    count: '153',
    arabic: 'اَيُّهَا',
    transliteration: '\'ayyuhaa',
    translations: [ 'o', 'oh'  ]
}, {
    count: '145',
    arabic: 'اِنَّمَا',
    transliteration: '\'innamma',
    translations: [ 'only' ]
}, {
    count: '142',
    arabic: 'حَتَّىٰ',
    transliteration: 'hatta',
    translations: [ 'Until' ]
// 21
}, {
    count: '137',
    arabic: 'أَمْ',
    transliteration: 'am',
    translations: [ 'or' ]
}, {
    count: '127',
    arabic: 'بَلْ',
    transliteration: 'bal',
    translations: [ 'rather' ]
}, {
    count: '123',
    arabic: 'لَعَلَّ',
    transliteration: 'la\'ala',
    translations: [ 'Maybe' ]
}, {
    count: '104',
    arabic: 'لَنْ',
    transliteration: 'lan',
    translations: [ 'Never' ]
}, {
    count: '93',
    arabic: 'هَلْ',
    transliteration: 'hal',
    translations: [ 'Is' ]
}, {
    count: '75',
    arabic: 'لَوْلَآ',
    transliteration: 'lowla',
    translations: [ 'if not for' ]
}, {
    count: '73',
    arabic: 'لٰكِنْ',
    transliteration: 'lakin',
    translations: [ 'however' ]
}, {
    count: '57',
    arabic: 'لٰكِنَّ',
    transliteration: 'lakinna',
    translations: [ 'however' ]
}, {
    count: '55',
    arabic: 'أَمَّا',
    transliteration: 'amma',
    translations: [ 'As for' ]
}, {
    count: '42',
    arabic: 'سَوْفَ',
    transliteration: 'sawfa',
    translations: [ 'soon' ]
// 31
}].map((a) => ({ ...a, color: 'gold' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const gold =  {
    number: `#${count.toLocaleString()} Particles ${percent}%`,
    words
}