const words = [{
    count: '461',
    arabic: 'أرْض',
    transliteration: 'ardd',
    translations: [ 'earth' ]
}, {
    count: '310',
    arabic: 'اَلسَّمَآء',
    transliteration: 'as-samma',
    translations: [ 'the heaven' ]
}, {
    count: '115',
    arabic: 'اَلْاَخِرَة',
    transliteration: 'Ad-aakhirat',
    translations: [ 'the hereafter' ]
}, {
    count: '115',
    arabic: 'اَلدُّنْيَا',
    transliteration: 'Ad-dunya',
    translations: [ 'the world' ]
}, {
    count: '73',
    arabic: 'اَلْعَلَمِيْنَ',
    transliteration: 'al-aalameena',
    translations: [ 'the worlds' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'yellowgreen' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const yellowgreen =  {
    number: `#${count.toLocaleString()} Life ${percent}%`,
    words
}