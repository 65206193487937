import './Home.css';

import { Link } from "react-router-dom";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container';

import { getRead, getChapter } from '../verse'

import { prayerHome } from '../prayers/home'

import { statementsRead } from '../purpose/statements'
import { statementsHome } from '../purpose/home'

import { invocationsHome } from '../invocation/home'

import { pie } from '../legend/pie'

import { chapter54 } from '../chapters/54'

const title = () => (<div>
  <span>ٱلْ&#x200d;</span><span style={{color: 'skyblue'}}>&#x200d;‍كِتَبْ</span> * Al-<span style={{color: 'skyblue'}}>Kitab</span> * The <span style={{color: 'skyblue'}}>Scripture</span> * <span style={{color: 'skyblue'}}>261</span>
</div>)

const link = ([path, title]) => (<Col><button>
  <Link to={ path }>{ title }</Link>
</button></Col>)

const info = () => (<Container>
  <hr></hr>
  <Row><h6>Read with a <b>color coded schema</b> with english <b>transliteration</b> and <b>translations</b>.</h6></Row>
  <Row><h6>Study <b>7</b> chapters from the Qur'an, <b>5</b> prayers, and <b>5</b> statements of purpose.</h6></Row>
  <Row><h6>Learn <b>50%</b> (~40,000) of the <b>Arabic</b> words in the Qur'an (~77,430).</h6></Row>
  <Row><h6>Review legend that highlights the <b>20</b> colors with <b>statistics</b>.</h6></Row>
</Container>)

const getHome = () => (<Container>
  <Row>
    { title() }
  </Row>
  <Row>
    { info() }
  </Row>
  <Row>
    { pie() }
    { link(['/legend', 'LEGEND']) }
  </Row>
  <Row>
    { getRead(chapter54) }
    { link(['/quran/read', 'CHAPTERS']) }
  </Row>
  <Row>
    { prayerHome() }
    { link(['/salah/study', 'PRAYERS']) }
  </Row>
  <Row>
    { invocationsHome() }
    { link(['/invocation/statement', 'INVOCATIONS']) }
  </Row>
  <Row>
    { statementsHome() }
    { link(['/purpose/statement', 'STATEMENTS']) }
  </Row>
  <Row>
    { getChapter(chapter54) }
    { link(['/quran/study', 'STUDY']) }
  </Row>
</Container>)

function Home() {
  return (
    <div className="App ">
      <header className="App-header">
        { getHome() }
      </header>
    </div>
  );
}

export default Home;
