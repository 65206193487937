import { getChapter, getRead } from '../verse'

const verses = {
    '108:1': {
        number: '١',
        words: [{
            count: '?',
            arabic: 'إِنَّآ',
            transliteration: 'innā',
            color: 'gold',
            translations: [ 'Indeed', 'Indeed, We' ],
            punctuations: [ '', ',' ]
        }, {
            count: '?',
            arabic: 'أَعْطَيْنَـٰكَ',
            transliteration: 'aʿṭaynāka',
            color: 'white',
            translations: [ 'We have given you ˹O Prophet˺', 'We have given you' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ٱلْكَوْثَرَ',
            transliteration: 'l-kawthara',
            color: 'white',
            translations: [ 'abundant goodness', 'Al-Kauthar' ],
            punctuations: [ '', '.' ]
        }]
    },
    '108:2': {
        number: '٢',
        words: [{
            count: '?',
            arabic: 'فَصَلِّ',
            transliteration: 'faṣalli',
            color: 'deepskyblue',
            translations: [ 'So pray' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لِرَبِّكَ',
            transliteration: 'lirabbika',
            color: 'skyblue',
            translations: [ 'to your Lord ˹alone˺' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَٱنْحَرْ',
            transliteration: 'wa-in\'ḥar',
            color: 'deepskyblue',
            translations: [ 'and (offer) sacrifice' ],
            punctuations: [ '', '.' ]
        }]
    },
    '108:3': {
        number: '٣',
        words: [{
            count: '?',
            arabic: 'إِنَّ',
            transliteration: 'inna',
            color: 'gold',
            translations: [ 'Indeed' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'شَانِئَكَ',
            transliteration: 'shāni-aka',
            color: 'red', 
            translations: [ 'your enemy' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'هُوَ',
            transliteration: 'huwa',
            color: 'cyan',
            translations: [ 'he (is)' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ٱلْأَبْتَرُ',
            transliteration: 'l-abtaru',
            color: 'red',
            translations: [ 'the one cut off' ],
            punctuations: [ '', '.' ]
        }]
    },
}

export const chapter108 = () => getChapter(verses)

export const chapter108Read = () => getRead(verses)