import { getChapter } from '../verse'

import {
    white, darkgrey, slategray, darkslategrey, 
    cadetblue, skyblue, royalblue, deepskyblue,
    cyan, mediumspringgreen, chartreuse, yellowgreen,
    gold, darkorange, sienna, brown,
    red, hotpink, fuchsia, rebeccapurple
} from './colors'

const verses = {
    white,
    darkgrey,
    slategray,
    darkslategrey,
    cadetblue,
    skyblue,
    royalblue,
    deepskyblue,
    cyan,
    mediumspringgreen,
    chartreuse,
    yellowgreen,
    gold,
    darkorange,
    sienna,
    brown,
    red,
    hotpink,
    fuchsia,
    rebeccapurple,
}

export const colors = () => getChapter(verses)
