import 'bootstrap/dist/css/bootstrap.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { span, rowH1, rowH5, rowH6 } from './row'

export const word = ({ count, arabic, transliteration, translations, color, punctuations }) => (<Col >
    { rowH6(count, color, ['', '']) }
    { rowH1(arabic, color, punctuations) }
    { rowH6(transliteration, color, punctuations) }
    { rowH6(translations[0], color, punctuations) }
    { translations[1] && rowH6(translations[1], color, punctuations) }
</Col>)

export const __getRead = (english, verse_, number, i) => (<Row key={{i}}>
    <hr></hr>
    { english }
    <div> { number }</div>
</Row>)

export const getStudy = (english, verse_, number, i) => (<Row className='arabicReverse'  key={{i}}>
    <hr></hr>
    { verse_ }
    <div> { number }</div>
</Row>)

export const getReadAndStudy = (english, verse_, number, i) => (<Container key={{i}}>
    <div><hr></hr></div>
    <div>{ english }</div>
    <div>{ number }</div>
    <hr></hr>
    <Row className='arabicReverse'>{ verse_ }</Row>
    <div>{ number }</div>
    <div><hr></hr></div>
</Container>)

const bb = (word, color, punctuations = ['', '']) => (<span>
    { punctuations[0] } { span(word, color) }{ punctuations[1] }
</span>)

export const smallWord = (words) => (<Row><h6>
    { words.map(({ word, color, punctuations }) => bb(`${word}`, color, punctuations)) }
</h6></Row>)

const arabicSmall = (word, color, punctuations = ['', '']) => (<span>
    {punctuations[0]} { span(word, color) } {punctuations[1]}
</span>)

export const smallArabicWord = (words) => (<Row><h4>
    { words.map(({ word, color, punctuations }) => arabicSmall(`${word} `, color, punctuations)) }
</h4></Row>)

export const smallTranslation = (arabicWords, transliterations, translations) => (<Container>
    {smallArabicWord(arabicWords)}
    {smallWord(transliterations)}
    {smallWord(translations)}
</Container>)

export const getSmallVerse = ({ words }) => {
    const arabicWords = words.map(({ arabic, color, punctuations }) => ({ word: arabic, color, punctuations }))
    const transliterations = words.map(({ transliteration, color, punctuations }) => ({ word: transliteration, color, punctuations }))
    const translations = words.map(({ translations, color, punctuations }) => ({ word: translations[0], color, punctuations }))
    return smallTranslation(arabicWords, transliterations, translations)
}

const _getVerse = (_verses) => [...Object.keys(_verses)].map((verse__, i) => {
    const { number, words } = _verses[verse__]
    const verses = words.map(word)
    const english = getSmallVerse(_verses[verse__])

    return getStudy(english, verses, number, i)
})

export const getChapter = (_verses) => (<Container>{ _getVerse(_verses) }</Container>)

const _getRead = (_verses) => [...Object.keys(_verses)].map((verse__, i) => {
    const { number, words } = _verses[verse__]
    const verses = words.map(word)
    const english = getSmallVerse(_verses[verse__])

    return __getRead(english, verses, number, i)
})

export const getRead = (_verses) => (<Container>{ _getRead(_verses) }</Container>)

const _getBoth = (_verses) => [...Object.keys(_verses)].map((verse__, i) => {
    const { number, words } = _verses[verse__]
    const verses = words.map(word)
    const english = getSmallVerse(_verses[verse__])

    return getReadAndStudy(english, verses, number, i)
})

export const getBoth = (_verses) => (<Container>{ _getBoth(_verses) }</Container>)
