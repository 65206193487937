import { getChapter, getRead } from '../verse'

const verses = {
    '112:1': {
        number: '١',
        words: [{
            count: '332',
            arabic: 'قُلْ',
            transliteration: 'qul',
            color: 'darkorange',
            translations: [ 'Say', 'Say ˹O Prophet˺' ],
            punctuations: [ '', ',' ]
        }, {
            count: '481',
            arabic: 'هُوَ',
            transliteration: 'huwa',
            color: 'cyan',
            translations: [ 'He' ],
            punctuations: [ '"', '' ]
        }, {
            count: '?',
            arabic: 'ٱللَّهُ',
            transliteration: 'l-lahu',
            color: 'skyblue',
            translations: [ '(is) God', '(is) Allah' ],
            punctuations: [ '', '']
        }, {
            count: '74',
            arabic: 'أَحَدٌ',
            transliteration: 'aḥadun',
            color: 'deepskyblue',
            translations: [ '(the) one' ],
            punctuations: ['', ',' ]
        }]
    },
    '112:2': {
        number: '٢',
        words: [{
            count: '?',
            arabic: 'ٱللَّهُ',
            transliteration: 'al-lahu',
            color: 'skyblue',
            translations: [ 'God', 'Allah' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'ٱلصَّمَدُ',
            transliteration: 'l-ṣamadu',
            color: 'white',
            translations: [ 'the Eternal, the Absolute', 'the Sustainer ˹needed by all˺' ],
            punctuations: [ '', '' ]
        }]
    },
    '112:3': {
        number: '٣',
        words: [{
            count: '348',
            arabic: 'لَمْ',
            transliteration: 'lam',
            color: 'gold',
            translations: [ 'Not' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'يَلِدْ',
            transliteration: 'yalid',
            color: 'white',
            translations: [ '(having) offspring', 'begets' ],
            punctuations: [ '', '' ]
        }, {
            count: '348',
            arabic: 'وَلَمْ',
            transliteration: 'walam',
            color: 'gold',
            translations: [ 'and not', 'neither' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'يُولَدْ',
            transliteration: 'yūlad',
            color: 'white',
            translations: [ 'born', 'begotten' ],
            punctuations: [ '', '' ]
            
        }]
    },
    '112:4': {
        number: '٤',
        words: [{
            count: '348',
            arabic: 'وَلَمْ',
            transliteration: 'walam',
            color: 'gold',
            translations: [ 'and not', 'neither' ],
            punctuations: [ '', '' ]
        }, {
            count: '31',
            arabic: 'يَكُن',
            transliteration: 'yakun',
            color: 'hotpink',
            translations: [ 'is' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'كُفُوًا',
            transliteration: 'kufuwan',
            color: 'white',
            translations: [ 'equivalent', 'comparable' ],
            punctuations: [ '', '' ]
        }, {
            count: '74',
            arabic: 'أَحَدٌۢ',
            transliteration: 'aḥadun',
            color: 'deepskyblue',
            translations: [ '(any) one' ],
            punctuations: [ '', '.' ]
        }]
    },
}

export const chapter112 = () => getChapter(verses)
export const chapter112Read = () => getRead(verses)