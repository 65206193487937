

import { getChapter, getRead } from '../verse'

const verses = {
    'a1': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:2': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'أَشْهَدُ',
            transliteration: '?',
            color: 'fuchsia',
            translations: [ 'I bear witness' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَنْ',
            transliteration: '?',
            color: 'gold',
            translations: [ 'that' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَّا',
            transliteration: '?',
            color: 'gold',
            translations: [ '[there is] no' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إِلٰهَ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إِلاَّ',
            transliteration: '?',
            color: 'gold',
            translations: [ 'except' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:3': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'أَشْهَدُ',
            transliteration: '?',
            color: 'fuchsia',
            translations: [ 'I bear witness' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَنْ',
            transliteration: '?',
            color: 'gold',
            translations: [ 'that' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مُحَمَّدًا',
            transliteration: '?',
            color: '',
            translations: [ 'Muhammad [PBOH]' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: ' رَّسُوْلُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the messenger' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:4': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'حَىَّ',
            transliteration: '?',
            color: 'fuchsia',
            translations: [ 'come' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَى',
            transliteration: '?',
            color: 'gold',
            translations: [ 'to' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الصَّلٰوةِ',
            transliteration: '?',
            color: 'deepskyblue',
            translations: [ 'the prayer' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:5': {
        number: '2 x times',
        words: [{
            count: '?',
            arabic: 'حَىَّ',
            transliteration: '?',
            color: 'fuchsia',
            translations: [ 'come' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَلَى',
            transliteration: '?',
            color: 'gold',
            translations: [ 'to' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'الْفَلَاحِ',
            transliteration: '?',
            color: '',
            translations: [ 'the prosperity' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:10': {
        // number: '١',
        number: 'Abu Dawud: 501 - Fajr 2 x times',
        words: [{
            count: '83',
            arabic: 'آلصَّلٰوةُ',
            transliteration: '?',
            color: 'deepskyblue',
            translations: [ 'Prayer' ],
            punctuations: [ '', '' ]
        }, {
            count: '17',
            arabic: 'خَيْرُ',
            transliteration: '?',
            color: 'darkgrey',
            translations: [ '<span style="color: white">[is]</span> better' ],
            punctuations: [ '', '' ]
        }, {
            count: '774?',
            arabic: 'مِنَ',
            transliteration: 'mina',
            color: 'hotpink',
            translations: [ 'than' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'النَّوْمِ',
            transliteration: '?',
            color: 'darkgrey',
            translations: [ 'sleep' ],
            punctuations: [ '', '.' ]
        }]
    },
    '1:6': {
        number: '1 x time',
        words: [{
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اَللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَكْبَرُ',
            transliteration: '?',
            color: '',
            translations: [ 'is the greatest' ],
            punctuations: [ '', '' ]
        }]
    },
    '1:7': {
        number: '1 x time',
        words: [{
            count: '?',
            arabic: 'لَا',
            transliteration: '?',
            color: 'gold',
            translations: [ '[there is] no' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إِلٰهَ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'إِلاَّ',
            transliteration: '?',
            color: 'gold',
            translations: [ 'except' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'اللّٰهُ',
            transliteration: '?',
            color: 'royalblue',
            translations: [ 'God' ],
            punctuations: [ '', '' ]
        }]
    },

    '1:11': {
        number: 'Abu Dawud: 510 - Iqamah 2 x times',
        words: [{
            count: '406?',
            arabic: 'قَدْ',
            transliteration: 'qad',
            color: 'gold',
            translations: [ 'Certainly' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'قامَتِ',
            transliteration: 'kamatee',
            color: 'fuchsia',
            translations: [ '(is) established' ],
            punctuations: [ '', '' ]
        }, {
            count: '83',
            arabic: '<span style="color: white">ال&#x200d;</span>&#x200d;صَّلٰوةُ',
            transliteration: '<span style="color: white">l-</span>salatu',
            color: 'deepskyblue',
            translations: [ '<span style="color: white">(the)</span> prayer' ],
            punctuations: [ '', '. ' ]
        }]
    },
}

export const zero = () => getChapter(verses)
export const zeroRead = () => getRead(verses)
