const words = [{
    count: '322',
    arabic: 'عَذَاب',
    transliteration: 'azaab',
    translations: [ 'torment', 'punishment' ]
}, {
    count: '145',
    arabic: 'اَلنَّار',
    transliteration: 'An-naar',
    translations: [ 'fire' ]
}, {
    count: '134',
    arabic: 'كافِر',
    transliteration: 'kaafir',
    translations: [ 'disbeliever' ]
}, {
    count: '88',
    arabic: 'التَّيْطٰنِ',
    transliteration: '?',
    translations: [ 'Satan' ],
    punctuations: [ '', '' ]
}, {
    count: '77',
    arabic: '?',
    transliteration: '?',
    translations: [ 'the Hell' ]
}, {
    count: '72',
    arabic: '?',
    transliteration: '?',
    translations: [ 'painful' ]
}, {
    count: '49',
    arabic: 'مُشْرِك',
    transliteration: 'mushrik',
    translations: [ 'polytheist' ]
}, {
    count: '40',
    arabic: 'شَرِيْك',
    transliteration: 'shareek',
    translations: [ 'partner' ]
}, {
    count: '39',
    arabic: 'اَلذُّنُوْب',
    transliteration: 'az-zunoob',
    translations: [ 'the sins' ]
}, {
    count: '36',
    arabic: '?',
    transliteration: '?',
    translations: [ 'woe onto' ]
}, {
    count: '32',
    arabic: 'جِنَّة',
    transliteration: 'jinnah',
    translations: [ 'Jinn' ]
}, {
    count: '26',
    arabic: '?',
    transliteration: '?',
    translations: [ 'hellfire' ]
}, {
    count: '20',
    arabic: '?',
    transliteration: '?',
    translations: [ 'chastisement (as a result of sin)' ]
}, {
    count: '16',
    arabic: '?',
    transliteration: '?',
    translations: [ 'blazing fire' ]
}, {
    count: '14',
    arabic: 'اَلضَّآلِّيْنَ',
    transliteration: 'ad-daalleena',
    translations: [ 'those who go astray' ],
}, {
    count: '6',
    arabic: 'الرَّجِيْمِ',
    transliteration: '?',
    translations: [ 'the outcast' ],
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'red' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const red =  {
    number: `#${count.toLocaleString()} Misguided ${percent}%`,
    words
}