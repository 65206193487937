const words = [{
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}, {
    count: '332',
    arabic: 'رَسُوْل',
    transliteration: 'rasool',
    translations: [ 'Messenger' ]
}, {
    count: '75',
    arabic: 'اَلنَّبِيِّ',
    transliteration: 'an-nabi',
    translations: [ 'The Prophet' ]
}, {
    count: '69',
    arabic: 'اِبْرَهِيْم',
    transliteration: 'ibraaheem',
    translations: [ 'Ibrahim' ]
}, {
    count: '4',
    arabic: 'مُحَمَّد',
    transliteration: 'Muhammad',
    translations: [ 'Muhammad' ]
}, {
    count: '0',
    arabic: '?',
    transliteration: '?',
    translations: [ '?' ]
}].map((a) => ({ ...a, color: 'royalblue' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const royalblue =  {
    number: `#${count.toLocaleString()} Prophets ${percent}%`,
    words
}