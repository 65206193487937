const words = [{
    count: '2454',
    arabic: 'مَا',
    transliteration: 'maa',
    translations: [ 'that which' ]
}, {
    count: '1080',
    arabic: 'ٱلَّذِينَ',
    transliteration: 'alladhina',
    translations: [ 'those who' ]
}, {
    count: '520',
    arabic: 'َذَلِك',
    transliteration: 'thalika',
    translations: [ 'that' ]
}, {
    count: '481',
    arabic: 'اَنَا',
    transliteration: '\'anna',
    translations: [ 'I' ]
}, {
    count: '481',
    arabic: 'هُوَ',
    transliteration: 'huwa',
    translations: [ 'he' ]
}, {
    count: '444',
    arabic: 'هُمْ',
    transliteration: 'hum',
    translations: [ 'they' ]
}, {
    count: '359',
    arabic: 'كُلُّ',
    transliteration: 'kullu',
    translations: [ 'everything' ]
}, {
    count: '304',
    arabic: 'اَلَّذِىْ',
    transliteration: 'allazee',
    translations: [ '(the one) who' ]
}, {
    count: '283',
    arabic: 'شَيْء',
    transliteration: 'shay',
    translations: [ 'thing' ]
}, {
    count: '255',
    arabic: 'هَذَا',
    transliteration: 'haazaa',
    translations: [ 'this' ]
}, {
    count: '216',
    arabic: 'عَلَيْ‍‍هِمْ',
    transliteration: 'allayhim',
    translations: [ 'on them' ]
}, {
    count: '204',
    arabic: 'اُولٰءكَ',
    transliteration: 'ulla\'ika',
    translations: [ 'those' ]
}, {
    count: '197',
    arabic: 'عِنْدَ',
    transliteration: 'inda',
    translations: [ 'with' ]
}, {
    count: '163',
    arabic: 'مَعَ',
    transliteration: 'ma\'a',
    translations: [ 'with' ]
}, {
    count: '147',
    arabic: 'غَيْر',
    transliteration: 'ghayr',
    translations: [ 'not of' ]
}, {
    count: '81',
    arabic: 'اَنْتَ',
    transliteration: '\'anta',
    translations: [ 'you' ]
}, {
    count: '86',
    arabic: 'نَحْنُ',
    transliteration: 'nahnu',
    translations: [ 'we' ]
}, {
    count: '68',
    arabic: 'اَنْتُمْ',
    transliteration: '\'antum',
    translations: [ 'you all' ]
}, {
    count: '64',
    arabic: 'هِىَ',
    transliteration: 'hiya',
    translations: [ 'she', 'it' ]
}, {
    count: '47',
    arabic: 'هَذَهِ',
    transliteration: 'haazihee',
    translations: [ 'this' ]
}, {
    count: '46',
    arabic: 'هَؤُلَآءِ',
    transliteration: 'haa\'ulla\'i',
    translations: [ 'these' ]
}, {
    count: '43',
    arabic: 'تِلْكَ',
    transliteration: 'tilka',
    translations: [ 'that', 'these' ]
}, {
    count: '43',
    arabic: 'اُولُوا',
    transliteration: 'uloo',
    translations: [ 'those of' ]
}, {
    count: '24',
    arabic: 'اَيَّاكَ',
    transliteration: 'iyyaaka',
    translations: [ 'you alone' ]
}].map((a) => ({ ...a, color: 'cyan' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const cyan =  {
    number: `#${count.toLocaleString()} Identification ${percent}%`,
    words    
}