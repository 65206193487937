import { getChapter, getRead } from '../verse'

const verses = {
    '111:1': {
        number: '١',
        words: [{
            count: '?',
            arabic: 'تَبَّتْ',
            transliteration: 'tabbat',
            color: 'pink',
            translations: [ 'Perish' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'يَدَآ',
            transliteration: 'yadā',
            color: 'grey',
            translations: [ '(the) hands' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَبِى',
            transliteration: 'abī',
            color: 'red',
            translations: [ '(of) Abu' ],
            punctuations: [ '', '' ]

        }, {
            count: '?',
            arabic: 'لَهَبٍ',
            transliteration: 'lahabin',
            color: 'red',
            translations: [ 'Lahab' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَّتَبَّ',
            transliteration: 'watabba',
            color: 'pink',
            translations: [ 'and perish he' ],
            punctuations: [ '', '' ]
        }]
    },
    '111:2': {
        number: '٢',
        words: [{
            count: '?',
            arabic: 'مَآ',
            transliteration: 'mā',
            color: 'gold',
            translations: [ 'Not' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'أَغْنَىٰ',
            transliteration: 'aghnā',
            color: 'pink',
            translations: [ '(will) avail' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'عَنْهُ',
            transliteration: 'ʿanhu',
            color: 'cyan',
            translations: [ 'him' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَالُهُۥ',
            transliteration: 'māluhu',
            color: 'grey',
            translations: [ 'his wealth' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'وَمَا',
            transliteration: 'wamā',
            color: 'white',
            translations: [ 'and what' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'كَسَبَ',
            transliteration: 'kasaba',
            color: 'blue',
            translations: [ 'he earned' ],
            punctuations: [ '', '' ]
        }]
    },
    '111:3': {
        number: '٣',
        words: [{
            count: '?',
            arabic: 'سَيَصْلَىٰ',
            transliteration: 'sayaṣlā',
            color: 'red',
            translations: [ 'He will be burnt' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'نَارًا',
            transliteration: 'nāran',
            color: 'orange',
            translations: [ '(in) a Fire' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ذَاتَ',
            transliteration: 'dhāta',
            color: 'gold',
            translations: [ 'of' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'لَهَبٍ',
            transliteration: 'lahabin',
            color: 'red',
            translations: [ 'Blazing Flames' ],
            punctuations: [ '', '' ]
        }],
    },
    '111:4': {
        number: '٤',
        words: [{
            count: '?',
            arabic: 'وَّامْرَأَتُهُُ',
            transliteration: 'wa-im\'ra-atuhu',
            color: 'cyan',
            translations: [ 'And his wife' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَمَّالَةَ',
            transliteration: 'ḥammālata',
            color: 'grey',
            translations: [ '(the) carrier' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'ٱلْحَطَبِ',
            transliteration: 'l-ḥaṭabi',
            color: 'brown',
            translations: [ '(of) firewood' ],
            punctuations: [ '', '' ]
        }]
    },
    '111:5': {
        number: '٥',
        words: [{
            count: '?',
            arabic: 'فِى',
            transliteration: 'fī',
            color: 'gold',
            translations: [ 'In' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'جِيدِهَا',
            transliteration: 'jīdihā',
            color: 'cyan',
            translations: [ 'her neck' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'حَبْلٌ',
            transliteration: 'ḥablun',
            color: 'grey',
            translations: [ '(will be) a rope' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مِّن',
            transliteration: 'min',
            color: 'gold',
            translations: [ 'of' ],
            punctuations: [ '', '' ]
        }, {
            count: '?',
            arabic: 'مَّسَدٍۭ',
            transliteration: 'masadin',
            color: 'grey',
            translations: [ 'palm-fiber' ],
            punctuations: [ '', '' ]
        }]
    }
}

export const chapter111 = () => getChapter(verses)

export const chapter111Read = () => getRead(verses)