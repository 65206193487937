const words = [{
    count: '0',
    arabic: 'النَّوْمِ',
    transliteration: '?',
    translations: [ 'sleep' ]
}, {
    count: '39',
    arabic: 'اِسْم',
    transliteration: '\'ism',
    translations: [ 'name' ]
}, {
    count: '73',
    arabic: 'اَلْعـٰاَمِيْنَ',
    transliteration: '\'al-\'aalameena',
    translations: [ 'the worlds' ]
}, {
    count: '63',
    arabic: 'كَثِيْر',
    transliteration: '\'kaseer',
    translations: [ 'much' ]

}, {
    count: '0',
    arabic: '؟',
    transliteration: '؟',
    translations: [ '؟' ]
}].map((a) => ({ ...a, color: 'darkgrey' }));

const count = words.reduce((sum, { count }) => sum + Number(count), 0)
const percent = ((count/ 77_430) * 100).toPrecision(4)

export const darkgrey =  {
    number: `#${count.toLocaleString()} Nouns ${percent}%`,
    words
}