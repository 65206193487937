
const content = () => (<p>There's nothing here!</p>)

function Error() {
  return (
    <div className="App ">
      <header className="App-header">
          { content() }
      </header>
    </div>
  );
}

export default Error;
