import { getChapter, getRead } from '../verse'

const verses = {
    '114:1': {
        number: '١',
        words: [{
            count: '332',
            arabic: 'قُلْ',
            transliteration: 'qul',
            color: 'fuchsia',
            translations: [ 'Say', 'Say ˹O Prophet˺' ],
            punctuations: [ '', ',' ]
        }, {
            count: '7',
            arabic: 'أَعُوذُ',
            transliteration: 'aʿūdhu',
            color: 'fuchsia',
            translations: [ 'I seek refuge', 'I seek protection'],
            punctuations: [ '"', '' ]
        }, {
            count: '<span style="color: royalblue">2471</span>',
            arabic: 'بِ&#x200d;<span style="color: royalblue">&#x200d;رَبِّ</span>',
            transliteration: 'bi<span style="color: royalblue">rabbi</span>',
            color: 'gold',
            translations: [ 'in (the) <span style="color: royalblue">Lord</span>', ],
            punctuations: [ '', '']
        }, {
            count: '241',
            arabic: 'ٱلنَّاسِ',
            transliteration: 'l-nāsi',
            color: 'chartreuse',
            translations: [ '(of) humankind', '(of) mankind' ],
            punctuations: ['', ',' ]
        }]
    },
    '114:2': {
        number: '٢',
        words: [{
            count: '13',
            arabic: 'مَلِكِ',
            transliteration: 'maliki',
            color: 'royalblue',
            translations: [ '(The) Master', '(The) King' ],
            punctuations: [ '', '' ]
        }, {
            count: '241',
            arabic: 'ٱلنَّاسِ',
            transliteration: 'l-nāsi',
            color: 'chartreuse',
            translations: [ '(of) humankind', '(of) mankind' ],
            punctuations: [ '', ',' ]
        }]
    },
    '114:3': {
        number: '٣',
        words: [{
            count: '145',
            arabic: 'إِلَـٰهِ',
            transliteration: 'ilāhi',
            color: 'skyblue',
            translations: [ '(The) God', ],
            punctuations: [ '', '' ]
        }, {
            count: '241',
            arabic: 'ٱلنَّاسِ',
            transliteration: 'l-nāsi',
            color: 'chartreuse',
            translations: [ '(of) humankind', '(of) mankind' ],
            punctuations: [ '', ',' ]
        }]
    },
    '114:4': {
        number: '٤',
        words: [{
            count: '2471',
            arabic: 'مِن',
            transliteration: 'min',
            color: 'hotpink',
            translations: [ 'From', ],
            punctuations: [ '', '' ]
        }, {
            count: '30',
            arabic: 'شَرِّ',
            transliteration: 'sharri',
            color: 'red',
            translations: [ '(the) evil', '(the) mischief' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'ٱلْوَسْوَاسِ',
            transliteration: 'l-waswāsi',
            color: 'linen',
            translations: [ '(of) the whispering', '(of) the whisperer' ],
            punctuations: [ '', ';' ]
        }, {
            count: '1',
            arabic: 'ٱلْخَنَّاسِ',
            transliteration: 'l-khanāsi',
            color: 'lightyellow',
            translations: [ '˹Elusive prompter˺ who returns again and again', 'the one who retreats' ],
            punctuations: [ '', ';' ]
        }]
    },
    '114:5': {
        number: '٥',
        words: [{
            count: '1080',
            arabic: 'ٱلَّذِى',
            transliteration: 'alladhī',
            color: 'cyan',
            translations: [ 'The one who' ],
            punctuations: [ '', '' ]
        }, {
            count: '1',
            arabic: 'يُوَسْوِسُ',
            transliteration: 'yuwaswisu',
            color: 'white',
            translations: [ 'whispers [evil]', 'whispers' ],
            punctuations: [ '', '' ]
        }, {
            count: '1684',
            arabic: 'فِى',
            transliteration: 'fī',
            color: 'gold',
            translations: [ 'into', 'in' ],
            punctuations: [ '', '' ]
        }, {
            count: '44',
            arabic: 'صُدُورِ',
            transliteration: 'ṣudūri',
            color: 'deeppink',
            translations: [ '(the) hearts', '(the) breasts' ],
            punctuations: [ '', '' ]
        }, {
            count: '241',
            arabic: 'ٱلنَّاسِ',
            transliteration: 'l-nāsi',
            color: 'chartreuse',
            translations: [ '(of) humankind', '(of) mankind' ],
            punctuations: [ '', ',' ]
        }]
    },
    '114:6': {
        number: '٦',
        words: [{
            count: '774?',
            arabic: 'مِنَ',
            transliteration: 'mina',
            color: 'hotpink',
            translations: [ 'From (among)', 'From' ],
            punctuations: [ '', '' ]
        }, {
            count: '32',
            arabic: 'ٱلْجِنَّةِ',
            transliteration: 'l-jinati',
            color: 'green',
            translations: [ 'the jinn' ],
            punctuations: [ '', '' ]
        }, {
            count: '<span style="color: chartreuse">241</span>',
            arabic: 'وَ&#x200d;<span style="color: chartreuse">&#x200d;ٱلنَّاسِ</span>',
            transliteration: 'wa<span style="color: chartreuse">l-nāsi</span>',
            color: 'gold',
            translations: [ 'and <span style="color: chartreuse">humankind</span>', 'and <span style="color: chartreuse">mankind</span>', ],
            punctuations: [ '', '.' ]
        }]
    },
}

export const chapter114 = () => getChapter(verses)
export const chapter114Read = () => getRead(verses)