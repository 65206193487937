
import { chapter1Read } from './1'
import { chapter103Read } from './103'
import { chapter108Read } from './108'
import { chapter109Read } from './109'
import { chapter110Read } from './110'
import { chapter111Read } from './111'
import { chapter112Read } from './112'
import { chapter113Read } from './113'
import { chapter114Read } from './114'


const title = (chapterTitle) => (<div>
  <h2>{chapterTitle}</h2>
  <br></br>
</div>)

function Read() {
  return (
    <div className="App ">
      <header className="App-header">
      <p>Under Construction!!!!</p>
        { title('1 - The Opening') }
        { chapter1Read() }
        <br></br>
        { title('103 - The Declining Day') }
        { chapter103Read() }
        <br></br>
        { title('108 - The Abundance') }
        { chapter108Read() }
        <br></br>
        { title('109 - The Unbelievers') }
        { chapter109Read() }
        <br></br>
        { title('110 - The Divine Support') }
        { chapter110Read() }
        <br></br>
        { title('111 - Palm Fiber') }
        { chapter111Read() }
        <br></br>
        { title('112 - Sincerity') }
        { chapter112Read() }
        <br></br>
        { title('113 - Day Break') }
        { chapter113Read() }
        <br></br>
        { title('114 - Mankind') }
        { chapter114Read() }
      </header>
    </div>
  );
}

export default Read;
