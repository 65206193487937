
import { statements } from './statements'

const title = (chapterTitle) => (<div>
  <h2>{chapterTitle}</h2>
  <br></br>
</div>)

function Statements() {
  return (
    <div className="App ">
    <header className="App-header">
      { title('Statements of Purpose') }
      { statements() }
    </header>
  </div>
  )
}

export default Statements;
